<script>
export default {
  props: ["showModal", "isOpen"],
  methods: {
    closeMenu() {
      // Emit an event to the parent component
      this.$emit("update:is-open", false);
    },
  },
};
</script>

<template>
  <!-- Header links -->
  <div
    :class="isOpen ? 'block' : 'hidden'"
    class="m-0 sm:ml-4 mt-5 sm:mt-3 md:flex p-5 sm:p-0 justify-center items-center"
  >
    <router-link
      to="/about"
      class="block text-left text-lg font-medium text-primary-dark dark:text-ternary-light hover:text-accent dark:hover:text-accent sm:mx-4 mb-2 sm:py-2"
      aria-label="about"
      @click="closeMenu"
      >About Me</router-link
    >

    <router-link
      to="/projects"
      class="block text-left text-lg font-medium text-primary-dark dark:text-ternary-light hover:text-accent dark:hover:text-accent sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark"
      aria-label="project"
      @click="closeMenu"
      >Projects</router-link
    >

    <router-link
      to="/blogs"
      class="block text-left text-lg font-medium text-primary-dark dark:text-ternary-light hover:text-accent dark:hover:text-accent sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark"
      aria-label="project"
      @click="closeMenu"
      >Blogs</router-link
    >

    <router-link
      to="/uses"
      class="block text-left text-lg font-medium text-primary-dark dark:text-ternary-light hover:text-accent dark:hover:text-accent sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark"
      aria-label="project"
      @click="closeMenu"
      >Uses</router-link
    >

    <router-link
      to="/contact"
      class="block text-left text-lg font-medium text-primary-dark dark:text-ternary-light hover:text-accent dark:hover:text-accent sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark"
      aria-label="Contact"
      @click="closeMenu"
      >Contacts</router-link
    >
  </div>
</template>

<style lang="scss" scoped></style>
