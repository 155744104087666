<script>
import AppBanner from "@/components/shared/AppBanner";
import HomeRecentProjects from "../components/home/HomeRecentProjects.vue";
import HomeContact from "../components/home/HomeContact.vue";
import HomeRecentBlogs from "../components/home/HomeRecentBlogs.vue";

export default {
  name: "Home",
  components: {
    AppBanner,
    HomeRecentProjects,
    HomeContact,
    HomeRecentBlogs,
  },
};
</script>

<template>
  <div class="container mx-auto mt-20 md:mt-32 xl:mt-36">
    <AppBanner class="mb-0 md:mb-5" />
    <HomeRecentProjects />
    <HomeRecentBlogs />
    <HomeContact />
  </div>
</template>

<style scoped></style>
