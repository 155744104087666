const blogs_items = [
  {
    id: 1,
    title: "Mastering Test-Driven Development for Reliable Code",
    category: "Software Engineering",
    date: "2023-11-14",
    color: "#fade10",
    img: require("@/assets/images/blogs/test-driven-development.webp"),
    short_desc:
      "Discover Test-Driven Development (TDD) and how it can lead to more reliable and maintainable code.",
    desc: `
    <h2>Introduction</h2>
    <p>Discover Test-Driven Development (TDD) and how it can lead to more reliable and maintainable code.</p>
    
    <h2>What is TDD?</h2>
    <p>Test-Driven Development is an innovative software development approach where tests are written before writing the bare minimum of code required for the test to be passed.</p>
    
    <h2>TDD Cycle</h2>
    <p>Learn about the TDD cycle of writing a test, making it run, and then refactoring the code, also known as the red/green/refactor cycle.</p>
    
    <h2>Benefits of TDD</h2>
    <p>Analyze the benefits of TDD, such as reducing bugs in production, facilitating changes, and fostering better design decisions.</p>
    
    <h2>Code Example</h2>
    <pre><code class="language-javascript">
    // TDD Example using JavaScript and Jest
    const sum = (a, b) => a + b;
    
    test('adds 1 + 2 to equal 3', () => {
      expect(sum(1, 2)).toBe(3);
    });
    </code></pre>
    `,
  },

  {
    id: 2,
    title:
      "The Evolution of DevOps: Bridging the Gap Between Development and Operations",
    category: "Software Engineering",
    date: "2023-11-13",
    color: "#d71110",
    img: require("@/assets/images/blogs/devops-evolution.webp"),
    short_desc:
      "Unpack the concept of DevOps and its significance in fostering a collaborative environment between software developers and",
    desc: `
    <h2>Introduction</h2>
    <p>Unpack the concept of DevOps and its significance in fostering a collaborative environment between software developers and IT professionals.</p>
    
    <h2>What is DevOps?</h2>
    <p>DevOps is a set of practices that combines software development (Dev) and IT operations (Ops) aiming to shorten the systems development life cycle and provide continuous delivery with high software quality.</p>
    
    <h2>DevOps Principles</h2>
    <p>Discuss the core principles of DevOps, including automation, continuous integration, and continuous delivery, and how they contribute to a more efficient production lifecycle.</p>
    
    <h2>DevOps Tools</h2>
    <p>Analyze the tools that play a crucial role in implementing DevOps practices, such as Docker, Jenkins, and Kubernetes.</p>
    
    <h2>Measuring DevOps Success</h2>
    <p>Understand the key performance indicators and metrics that can help measure the success and impact of DevOps within an organization.</p>
    
    <h2>Code Example</h2>
    <pre><code class="language-javascript">
    // Automated Deployment Script using Node.js
    const { exec } = require('child_process');
    
    function deploy(projectName) {
      console.log(\`Starting deployment for \${projectName}\`);
      exec('git pull && npm install && pm2 restart all', (err, stdout, stderr) => {
        if (err) {
          console.error('Deployment error:', err);
          return;
        }
        console.log('Deployment stdout:', stdout);
        console.error('Deployment stderr:', stderr);
      });
    }
    
    deploy('my-awesome-app');
    </code></pre>
    `,
  },

  {
    id: 4,
    title: "Decoding Vue.js: A Primer for New Developers",
    category: "Web Development",
    date: "2023-11-12",
    color: "#5aaa49",
    img: require("@/assets/images/blogs/vuejs-primer.webp"),
    short_desc:
      "Vue.js is a progressive JavaScript framework for building user interfaces. This blog post serves as a primer for new developers interested",
    desc: `
    <h2>Introduction</h2>
    <p>Vue.js is a progressive JavaScript framework for building user interfaces. This blog post serves as a primer for new developers interested in this intuitive and flexible framework.</p>

    <h2>Getting Started with Vue.js</h2>
    <p>Learn the basics of Vue.js, from setting up your development environment to creating your first Vue component. Understand the Vue instance, the virtual DOM, and reactivity principles.</p>

    <h2>Core Features of Vue.js</h2>
    <p>Dive into Vue.js core features such as components, directives, and the Vue CLI. Discover how to enhance your app with reusable components and custom directives.</p>

    <h2>Vue.js Ecosystem</h2>
    <p>Explore the rich Vue.js ecosystem, including state management with Vuex, routing with Vue Router, and building full-fledged SPAs (Single Page Applications).</p>

    <h2>Conclusion</h2>
    <p>By the end of this blog, you’ll have a solid understanding of Vue.js and be ready to start building interactive and dynamic web applications.</p>
    `,
  },

  {
    id: 5,
    title: "Exploring Architectural Patterns in Software Design",
    category: "Software Engineering",
    date: "2023-11-15",
    color: "#2d5376",
    img: require("@/assets/images/blogs/architectural-patterns.webp"),
    short_desc:
      "Examine the role of architectural patterns in software design and how they provide a blueprint for solving common design",
    desc: `
    <h2>Introduction</h2>
    <p>Examine the role of architectural patterns in software design and how they provide a blueprint for solving common design challenges.</p>
    
    <h2>Common Architectural Patterns</h2>
    <p>Explore some of the most widely used architectural patterns like MVC, MVP, and MVVM, and how they dictate the organization of code and the flow of control in software applications.</p>
    
    <h2>Choosing the Right Pattern</h2>
    <p>Discuss the factors to consider when choosing an architecture pattern for your project, including the team's expertise, system requirements, and business goals.</p>
    
    <h2>Architecture Anti-Patterns</h2>
    <p>Learn about common architectural anti-patterns to avoid, which can lead to poorly designed systems and how to mitigate these risks.</p>
    
    <h2>Architectural Trade-Offs</h2>
    <p>Understand the trade-offs involved in architectural decision-making, such as performance vs. scalability or consistency vs. availability.</p>
    
    <h2>Code Example</h2>
    <pre><code class="language-javascript">
    // MVC Pattern in JavaScript
    // Model
    class Book {
      constructor(title, author) {
        this.title = title;
        this.author = author;
      }
    }
    
    // View
    class BookView {
      constructor(model) {
        this.model = model;
      }
    
      update() {
        console.log(\`Book: \${this.model.title} by \${this.model.author}\`);

      }
    }
    
    // Controller
    class BookController {
      constructor(model, view) {
        this.model = model;
        this.view = view;
      }
    
      setBookTitle(title) {
        this.model.title = title;
        this.view.update();
      }
    }
    
    const book = new Book('1984', 'George Orwell');
    const view = new BookView(book);
    const controller = new BookController(book, view);
    
    controller.setBookTitle('Animal Farm');
    </code></pre>
    `,
  },
  {
    id: 6,
    title: "Exploring Node.js: The Backbone of Modern Web Apps",
    category: "Web Development",
    date: "2023-11-13",
    color: "#00c287",
    img: require("@/assets/images/blogs/nodejs-backbone.webp"),
    short_desc:
      "Node.js has become a cornerstone of modern web development. This blog explores how Node.js works and why it's become so popular",
    desc: `
    <h2>Introduction</h2>
    <p>Node.js has become a cornerstone of modern web development. This blog explores how Node.js works and why it's become so popular among developers.</p>

    <h2>What is Node.js?</h2>
    <p>Understand what Node.js is and its non-blocking, event-driven architecture that allows for building scalable network applications.</p>

    <h2>Node.js Features and Benefits</h2>
    <p>Discover the features that make Node.js a top choice for backend development, including its package manager npm and the vast ecosystem of modules.</p>

    <h2>Building a Simple App with Node.js</h2>
    <p>Step-by-step guide on creating a simple but functional web application using Node.js and Express framework, demonstrating the ease of development and deployment.</p>

    <h2>Conclusion</h2>
    <p>Gain insights into the power of Node.js and how it can be used to build efficient, real-time web applications for any scale.</p>
    `,
  },
  {
    id: 7,
    title: "Responsive Design Simplified: A Mobile-First Approach",
    category: "Web Development",
    date: "2023-11-14",
    color: "#3c5e42",
    img: require("@/assets/images/blogs/responsive-design.webp"),
    short_desc:
      "Responsive web design is a must in the mobile age. This blog provides a comprehensive guide to implementing a mobile-first design strategy.",
    desc: `
    <h2>Introduction</h2>
    <p>Responsive web design is a must in the mobile age. This blog provides a comprehensive guide to implementing a mobile-first design strategy.</p>

    <h2>Understanding Responsive Design</h2>
    <p>Learn the principles of responsive design, including fluid grids, flexible images, and media queries that adapt your content to fit various screen sizes.</p>

    <h2>Mobile-First Strategy</h2>
    <p>Discover the importance of a mobile-first approach, starting your design process with mobile screens and then scaling up to larger devices.</p>

    <h2>Tools and Frameworks</h2>
    <p>Get to know the tools and frameworks that can help streamline the responsive design process, like Bootstrap, Foundation, and CSS Grid Layout.</p>

    <h2>Conclusion</h2>
    <p>Embrace the mobile-first philosophy to ensure your websites provide an optimal viewing experience on all devices, from phones to desktops.</p>
    `,
  },
  {
    id: 8,
    title: "Harnessing the Power of APIs in Web Development",
    category: "Web Development",
    date: "2023-11-15",
    color: "#fabe39",
    img: require("@/assets/images/blogs/apis-power.webp"),
    short_desc:
      "APIs are the building blocks of modern web applications. This blog discusses the role of APIs in web development and",
    desc: `
    <h2>Introduction</h2>
    <p>APIs are the building blocks of modern web applications. This blog discusses the role of APIs in web development and how they can be leveraged to create powerful web services.</p>

    <h2>What Are APIs?</h2>
    <p>Gain a fundamental understanding of APIs (Application Programming Interfaces) and how they allow different software systems to communicate with each other.</p>

    <h2>Types of APIs</h2>
    <p>Explore the different types of APIs, including REST, GraphQL, and SOAP. Learn their use cases and why REST has become the standard for web services.</p>

    <h2>Creating Your First API</h2>
    <p>Follow a tutorial on building your first API with Node.js and Express, covering the basics of endpoints, HTTP methods, and status codes.</p>

    <h2>Conclusion</h2>
    <p>Understand the significance of APIs in today’s interconnected digital ecosystem and how mastering them can lead to more robust and dynamic web applications.</p>
    `,
  },

  {
    id: 9,
    title: "Subject Review : Computer Science & Engineering (CSE)",
    category: "Software Engineering",
    date: "2023-11-12",
    color: "#e8af59",
    img: require("@/assets/images/blogs/subject-review-cse.webp"),
    short_desc:
      "সিএসই পড়তে গেলে যেই জিনিসটা খুবই দরকারী সেটা হলো ক্রিয়েটিভিটি, প্যাশন, লিডারশীপ কোয়ালিটি এবং অফকোর্স মাথায় রাখতে হবে সবসময় সিএসই অন্য যে  ",
    desc: `
    <p>সিএসই পড়তে গেলে যেই জিনিসটা খুবই দরকারী সেটা হলো ক্রিয়েটিভিটি, প্যাশন, লিডারশীপ কোয়ালিটি এবং অফকোর্স মাথায় রাখতে হবে সবসময় সিএসই অন্য যে কোন সাবজেক্টের চাইতে আলাদা। এটার সাথে অনেকটা চারুকলা, আর্কিটেকচারের মিল আছে। যে কোন সাবজেক্টের ইন্ডাস্ট্রীতে চাকরী পেতে হলে লাগে ভালো রেজাল্ট, মামা চাচার জোড়, রুপচাদা খাটি সয়াবিন তেল এবং টিকে থাকতে গেলে চালিয়ে যেতে হবে পলিটিক্স এবং তেল। এগুলাই উন্নতির চাবিকাঠি।</p>
    <p>কিন্তু সিএসইতে তোমার রেজাল্ট যদি ২ পয়েন্ট সামথিং থাকে (আউট অফ ৪), একগাদা ড্রপ কোর্স থাকে, হাতে সার্টিফিকেটও না থাকে তবুও তুমি ইন্ডাস্ট্রী কাপিয়ে দিতে পারবে যদি তোমার ক্রিয়েটিভিটি, প্যাশন, লিডারশীপ থাকে। কারণ এই তিনটা জিনিসের কম্বিনেশনে তুমি একটা জিনিসে বস হয়ে যেতে পারবে যেটাকে বলা হয় প্রোগ্রামীং।</p>
    <p>এখন একটু ভেঙে বলি এ তিনটা জিনিস কেন দরকার।</p>
    <ul>
        <li>ক্রিয়েটিভিটি : তোমার কি রাতের বেলায় আইডিয়ার ঠেলায় ঘুম আসেনা? তাহলে তুমি সফটওয়ার ডেভলপমেন্টে ভাল করবে। কারণ ইন্ডাস্ট্রী ক্রিয়েটিভিটি চায়। দিন যাচ্ছে আর মানুষের কাছে চাহিদা বাড়ছে নতুন নতুন টেকনোলজির। সুতরাং নতুন কিছু বানানোর মত ক্ষমতা তোমার থাকতে হবে। তোমার মাথায় সবসময় নতুন কিছু ঘুরপাক খেতে হবে। নাহলে একাডেমিক প্রজেক্ট করার সময় তোমাকে দিশেহারা হয়ে ঘুরতে হবে। ফাইনাল ইয়ারে থিসিসের টপিক খুজে পাবেনা যেটা খুবই দু:খজনক ব্যাপার ৩ বছর পড়াশোনা করার পর।</li>
        <li>প্যাশন : নতুন কিছু বানানোর ক্ষমতা থাকলেই হবেনা সেই সাথে ইচ্ছাটাও থাকতে হবে। ক্রিয়েটিভ লোকজন সবচাইতে বেশী হতাশায় ভোগে। সেই হতাশা তোমাকে কাটিয়ে উঠতে হবে। সব বাধা ভেঙে দিয়ে কিছু করতে হলে প্যাশন থাকা জরুরী এবং সেই প্যাশনটা অবশ্যই সিএসইর জন্যই হতে হবে। সিএসইতে পড়ে পলিটিক্স নিয়ে প্যাশন থাকলে সিএসইর কোন আউটকাম আসবেনা এটা কনফার্ম।</li>
        <li>লিডারশীপ : A Leader is a by born Problem Solver. সিএসই পড়ার মানেই হলো নতুন নতুন প্রবলেম ম্যাথমেটিকসের লজিক এপ্লাই করে, ফিজিক্সের রুল দিয়ে সলভ করা। যে রিয়েল লাইফে ভাল প্রবলেম সলভার সে খুব ভালভাবে জানে একটা প্রবলেম কিভাবে সলভ করতে হয়। তাছাড়া একটা যে কোন লেন্থি কাজ আজকে কিভাবে শুরু করলে দশ
         দিন পরে এটার ফল কি হবে এটা লিডার ছাড়া কেউ বলতে পারবেনা। লিডারশীপ যার মধ্যে থাকে তার মধ্যে ক্রিয়েটিভিটিও অটোমেটিক চলে আসে।</li>
    </ul>
    <p>স্বপ্ন দেখতে হবে এবং স্বপ্ন সত্যি করার জন্য এনাফ গাটস থাকতে হবে। সাবজেক্টটার প্রতি ভালবাসা না থাকলে ভাল করা সম্ভব না।</p>
    <p>একটা ছোট্ট উদাহরণ দেই একজন বড়ভাইয়ের। উনি শাবির ৯৭/৯৮ ব্যাচের মনির ভাই। পাগল টাইপের মানুষ। ক্লাশ করতেননা। দিনরাত নতুন নতুন সফটওয়ার বানাতেন। অনেক ড্রপ কোর্স ছিলো। রেজাল্ট ৩ এর নীচে। উনার ব্যাচের ৪/২ পরীক্ষার পরে উনার অনেক কোর্স বাকী ছিলো। সার্টিফিকেট ছাড়াই উনি চাকরী পেয়ে গেলেন মাইক্রোসফট এ। জাফর স্যার উনাকে আমেরিকা থেকে আনিয়ে কোর্সগুলা কমপ্লিট করিয়েছিলেন। কথায় কথায় স্যার একবার মনির ভাইকে জিজ্ঞেস করেছিলেন যে তোমার রেজাল্ট, সার্টিফিকেট নিয়ে এরা কিছু বলেনা? মনির ভাই উত্তর দিলেন, "না। ওরা ভয় পায় আমি যদি তাহলে চাকরী ছেড়ে দেই।"</p>
    
    <p>
    লিখেছেন সুদীপ্ত কর - CSE 4/2, SUST</p>
    `,
  },
];

const blogs = blogs_items.map((blog) => {
  const title = blog.title.replace(/ /g, "-").toLowerCase();
  const permalink = title;
  return {
    ...blog,
    permalink,
  };
});

export default blogs;
