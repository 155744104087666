const projects_items = [
  {
    id: 4,
    title: "My Life Plans - Jamaican Client Project",
    category: "Raw PHP",
    tags: ["PHP", "Client Project"],
    live_link: "https://mylifeplans.xyz/",
    github_link: "",
    color: "#6ac4c5",
    date: "23 March 2021",
    desc: `
      <h2>Project Overview</h2>
      <p><strong>Client:</strong> My Life Plans<br>
         <strong>Industry:</strong> Personal Development and Planning<br>
         <strong>Platform:</strong> Raw PHP<br>
         <strong>Website:</strong> <a href='https://mylifeplans.xyz/' target='_blank'>My Life Plans</a></p>
      <p>My Life Plans is a comprehensive project designed for individuals seeking guidance and tools for personal development and life planning. The website was developed using raw PHP, offering a robust, customized experience to users looking for structured life planning solutions.</p>
  
      <h2>Project Goal</h2>
      <p>The main objective was to create a dynamic and interactive website that provides users with personalized planning tools and resources. The focus was on building a platform that is both functional and user-friendly, catering to individuals seeking to organize and improve various aspects of their lives.</p>
  
      <h2>Design and Development Insights</h2>
      <p>Developed entirely in raw PHP, the site offers a unique and tailored user experience. The design is clean and intuitive, with a color scheme that promotes a sense of calm and organization. Emphasis was placed on creating a seamless and efficient user journey through the planning tools and resources available on the site.</p>
  
      <h2>Key Features</h2>
      <ul>
        <li><strong>Personalized Planning Tools:</strong> Customizable tools that help users in setting and tracking personal goals.</li>
        <li><strong>Resource Library:</strong> A collection of articles, tips, and guides on personal development and life planning.</li>
        <li><strong>User-Friendly Interface:</strong> A clear and navigable layout that enhances the overall user experience.</li>
        <li><strong>Interactive Elements:</strong> Features that engage users and encourage active participation in their life planning journey.</li>
      </ul>
  
      <h2>Outcome and Impact</h2>
      <p>My Life Plans has successfully established itself as a valuable resource for individuals pursuing personal growth and better life organization. The website, with its bespoke PHP development, has been well-received for its effectiveness in helping users achieve their personal development goals.</p>
    `,
    img: require("@/assets/images/projects/my-life-plans.webp"),
  },

  {
    id: 5,
    title: "Pinbadgesrus - First Fiverr Order :)",
    category: "WordPress Customization",
    tags: ["WordPress", "Ecommerce", "Client Project"],
    live_link: "https://pinbadgesrus.co.uk/",
    github_link: "",
    color: "#00FFFC",
    date: "25 March 2021",
    desc: `
      <h2>Project Overview</h2>
      <p><strong>Client:</strong> Pinbadgesrus<br>
         <strong>Industry:</strong> Ecommerce - Custom Pin Badges<br>
         <strong>Platform:</strong> WordPress<br>
         <strong>Website:</strong> <a href='https://pinbadgesrus.co.uk/' target='_blank'>Pinbadgesrus</a></p>
      <p>Pinbadgesrus marks a significant milestone as the first Fiverr order, showcasing a blend of creativity and technical proficiency. The project involved developing a vibrant and dynamic e-commerce website dedicated to custom pin badges, appealing to a wide range of customers, from collectors to corporate clients.</p>
  
      <h2>Project Goal</h2>
      <p>Our mission was to create an online platform that not only highlights the uniqueness of custom pin badges but also ensures a seamless and engaging shopping experience. The website was designed to captivate customers with its aesthetic appeal and user-friendly functionality.</p>
  
      <h2>Design and Development Insights</h2>
      <p>The design of Pinbadgesrus is a standout feature, incorporating a bright and inviting color palette that instantly grabs attention. The site’s layout is meticulously crafted to guide customers through a rich catalogue of pin badges with ease, while ensuring secure and efficient transaction processes.</p>
  
      <h2>Key Features</h2>
      <ul>
        <li><strong>Product Showcase:</strong> A diverse range of pin badges displayed with high-resolution images and detailed descriptions.</li>
        <li><strong>Intuitive User Experience:</strong> Streamlined navigation and search functionality for a hassle-free shopping journey.</li>
        <li><strong>Secure Checkout:</strong> Robust and reliable payment gateways ensuring a safe purchasing experience.</li>
        <li><strong>Customer Engagement:</strong> Interactive features such as customer reviews and ratings to build trust and community.</li>
        <li><strong>Contact and Support:</strong> Easy access to customer service for inquiries and assistance.</li>
      </ul>
  
      <h2>Outcome and Impact</h2>
      <p>Pinbadgesrus has become a shining example of successful e-commerce web development, reflecting the blend of aesthetic appeal and functionality. The website has significantly boosted sales and customer engagement, marking a triumphant entry into the world of freelance web development.</p>
    `,
    img: require("@/assets/images/projects/pinbadgesrus.webp"),
  },

  {
    id: 6,
    title: "Zulufins - A Webshop for Selling Fins :)",
    category: "WordPress Customization",
    tags: ["WordPress", "Ecommerce", "Client Project"],
    live_link: "https://zulufins.nl/",
    github_link: "",
    color: "#89b1bb",
    date: "01 May 2021",
    desc: `
      <h2>Project Overview</h2>
      <p><strong>Client:</strong> Zulufins<br>
         <strong>Industry:</strong> Ecommerce - Sporting Goods<br>
         <strong>Platform:</strong> WordPress<br>
         <strong>Website:</strong> <a href='https://zulufins.nl/' target='_blank'>Zulufins</a></p>
      <p>Zulufins is an engaging webshop specializing in the sale of high-quality fins for various water sports. The project involved creating a user-friendly e-commerce platform that showcases a wide range of fins, catering to both enthusiasts and professional athletes.</p>
  
      <h2>Project Goal</h2>
      <p>The goal was to develop a dynamic and visually appealing online store that provides customers with a seamless shopping experience. The website needed to effectively display product details, facilitate easy navigation, and include secure payment options for buyers.</p>
  
      <h2>Design and Development Insights</h2>
      <p>The website's design was focused on the brand’s aquatic theme, with a color palette reflecting the serenity of the ocean. The layout was structured for easy access to product categories, detailed product pages, and a straightforward checkout process.</p>
  
      <h2>Key Features</h2>
      <ul>
        <li><strong>Product Showcase:</strong> Displaying a variety of fins with detailed descriptions and high-quality images.</li>
        <li><strong>Easy Navigation:</strong> An intuitive interface allowing customers to effortlessly browse and find products.</li>
        <li><strong>Secure Payment System:</strong> Integrating various secure payment methods for hassle-free transactions.</li>
        <li><strong>Customer Reviews:</strong> Enabling customers to provide feedback and reviews on products.</li>
        <li><strong>Contact Information:</strong> Providing clear and accessible contact details for customer inquiries and support.</li>
      </ul>
  
      <h2>Outcome and Impact</h2>
      <p>Since its launch, Zulufins has become a popular destination for water sports enthusiasts seeking quality fins. The website has facilitated increased sales and customer satisfaction by offering an accessible and reliable online shopping experience.</p>
    `,
    img: require("@/assets/images/projects/zulufins.nl.webp"),
  },

  {
    id: 7,
    title: "SipsJamaica - Water Delivery Ecommerce",
    category: "WordPress Customization",
    tags: ["WordPress", "Ecommerce", "Client Project"],
    live_link: "https://sipsjamaica.com/",
    github_link: "",
    color: "#379eff",
    date: "23 June 2021",
    desc: `
      <h2>Project Overview</h2>
      <p><strong>Client:</strong> SipsJamaica<br>
         <strong>Industry:</strong> Ecommerce - Water Delivery<br>
         <strong>Platform:</strong> WordPress<br>
         <strong>Website:</strong> <a href='https://sipsjamaica.com/' target='_blank'>SipsJamaica</a></p>
      <p>SipsJamaica, a prominent water delivery service, underwent a complete website rebuild after the original site became obsolete due to unsupported hosting infrastructure. The new website was reconstructed from scratch, retaining the beloved original design while incorporating modern features to stay current and enhance user experience.</p>
  
      <h2>Project Goal</h2>
      <p>The objective was to modernize the SipsJamaica website, preserving the familiar look and feel for existing customers while introducing advanced functionalities. The site aimed to provide a seamless and efficient water delivery ecommerce experience, reflecting the brand’s commitment to quality service.</p>
  
      <h2>Design and Development Insights</h2>
      <p>The redesigned website focuses on user engagement and operational efficiency. It boasts a clean layout, intuitive navigation, and updated e-commerce functionalities, ensuring an easy and reliable ordering process for water delivery services.</p>
  
      <h2>Key Features</h2>
      <ul>
        <li><strong>About Us:</strong> Detailing the company's mission and values.</li>
        <li><strong>Our Products:</strong> Featuring a catalog of water products available for delivery.</li>
        <li><strong>Board Of Directors:</strong> Introducing the leadership team behind SipsJamaica.</li>
        <li><strong>Contact Us:</strong> Offering customers a direct line for inquiries and support.</li>
        <li><strong>Frequently Asked Questions:</strong> Providing answers to common customer queries.</li>
        <li><strong>Refund and Returns Policy:</strong> Outlining policies for customer satisfaction and trust.</li>
      </ul>
  
      <h2>Outcome and Impact</h2>
      <p>The revitalized SipsJamaica website has successfully enhanced the brand’s online presence and streamlined the water delivery process. It has improved customer engagement and satisfaction, playing a crucial role in the company’s ongoing success and growth in the ecommerce sector.</p>
    `,
    img: require("@/assets/images/projects/sipsjamaica.webp"),
  },

  {
    id: 8,
    title: "Curvyladiesfashion - UK Returning Buyer Ecommerce Website",
    category: "WordPress Customization",
    tags: ["WordPress", "Ecommerce", "Client Project"],
    live_link: "https://curvyladiesfashion.co.uk/",
    github_link: "",
    color: "#379eff",
    date: "04 August 2021",
    desc: `
      <h2>Project Overview</h2>
      <p><strong>Client:</strong> Curvyladiesfashion<br>
         <strong>Industry:</strong> Fashion and Retail<br>
         <strong>Platform:</strong> WordPress<br>
         <strong>Website:</strong> <a href='https://curvyladiesfashion.co.uk/' target='_blank'>Curvyladiesfashion</a></p>
      <p>Curvyladiesfashion is a UK-based e-commerce website specializing in the sale of fusion fashion garments. The project was focused on creating a vibrant and user-friendly online shopping platform, catering specifically to returning buyers looking for quality and trendy fashion options.</p>
  
      <h2>Project Goal</h2>
      <p>The primary goal was to develop an e-commerce website that provides an engaging and seamless shopping experience. The site was designed to be visually appealing and easy to navigate, encouraging repeat visits and purchases from fashion-conscious consumers.</p>
  
      <h2>Design and Development Insights</h2>
      <p>The design of the website was tailored to reflect the brand's stylish and modern appeal. Emphasis was placed on creating an intuitive user interface that facilitates easy browsing and purchasing. The site's color scheme and layout were chosen to enhance the visual appeal of the products and the overall shopping experience.</p>
  
      <h2>Key Features</h2>
      <ul>
        <li><strong>Product Showcase:</strong> Highlighting a wide range of fashion items with detailed descriptions and imagery.</li>
        <li><strong>Easy Navigation:</strong> A user-friendly interface that allows customers to effortlessly find and purchase products.</li>
        <li><strong>Customer Account Management:</strong> Personalized accounts for returning buyers to track orders and preferences.</li>
        <li><strong>Secure Payment Options:</strong> Integrating multiple secure payment methods for customer convenience.</li>
        <li><strong>Contact and Support:</strong> Dedicated sections for customer queries and support.</li>
      </ul>
  
      <h2>Outcome and Impact</h2>
      <p>Since its launch, Curvyladiesfashion has become a go-to online destination for fusion fashion enthusiasts in the UK. The website has successfully attracted and retained a significant number of returning buyers, contributing positively to the brand’s growth and market presence.</p>
    `,
    img: require("@/assets/images/projects/curvyladiesfashion.webp"),
  },

  {
    id: 9,
    title: "Polio - HTML to WordPress",
    category: "WordPress Development",
    tags: ["WordPress", "Kirki Framework", "Practice Project"],
    live_link: "https://polio.techviewing.com/",
    github_link: "",
    color: "#89b1bb",
    date: "04 August 2021",
    desc: `
      <h2>Project Overview</h2>
      <p>
         <strong>Platform:</strong> WordPress<br>
         <strong>Website:</strong> <a href='https://polio.techviewing.com/' target='_blank'>Polio</a></p>
      <p>Polio represents a strategic project focused on converting a static HTML website into a dynamic WordPress site. This transformation was expertly carried out using the Kirki Customizer Framework, emphasizing flexibility and user-friendly site management. The project served as a practice project to demonstrate advanced WordPress development skills.</p>
  
      <h2>Project Goal</h2>
      <p>The objective was to showcase the capabilities of WordPress as a powerful content management system by transforming a static HTML site into a fully functional WordPress website. The project aimed to maintain the original design aesthetics while enhancing functionality and user experience using the Kirki Framework.</p>
  
      <h2>Design and Development Insights</h2>
      <p>The development process involved leveraging the Kirki Framework for custom theme settings, allowing for greater control and customization of the WordPress environment. The focus was on ensuring a seamless transition from HTML to WordPress, maintaining design integrity while introducing an enhanced, intuitive user interface.</p>
  
      <h2>Key Features</h2>
      <ul>
        <li><strong>Custom WordPress Theme:</strong> A tailor-made theme that mirrors the original HTML design with enhanced functionality.</li>
        <li><strong>Kirki Customizer Framework:</strong> Utilization of Kirki for advanced theme customization options and better user control.</li>
        <li><strong>Responsive Design:</strong> Ensuring the website is fully responsive and accessible across various devices and screen sizes.</li>
        <li><strong>Improved User Experience:</strong> Focus on user experience with interactive and user-friendly design elements.</li>
      </ul>
  
      <h2>Outcome and Impact</h2>
      <p>The Polio project effectively demonstrates the successful conversion of a static HTML site to a dynamic WordPress site, showcasing advanced development techniques. It serves as a testament to the power of WordPress in creating customizable, responsive, and user-friendly websites.</p>
    `,
    img: require("@/assets/images/projects/polio.webp"),
  },

  {
    id: 10,
    title: "Coulro Phobia – coulrosapien",
    category: "WordPress Customization",
    tags: ["WordPress", "Client Project"],
    live_link: "https://coulrosapien.com/",
    github_link: "",
    color: "#75070d",
    date: "01 January 2022",
    desc: `
      <h2>Project Overview</h2>
      <p><strong>Client:</strong> Coulro Phobia – coulrosapien<br>
         <strong>Industry:</strong> Entertainment<br>
         <strong>Platform:</strong> WordPress<br>
         <strong>Website:</strong> <a href='https://coulrosapien.com/' target='_blank'>Coulro Phobia – coulrosapien</a></p>
      <p>Coulro Phobia – coulrosapien offers a unique and immersive 'Killer Clown' experience, captivating audiences with a blend of horror and entertainment. The website serves as the digital front for Camp Coulro Phobia Inc., introducing visitors to this spine-chilling experience through engaging content and character showcases.</p>
  
      <h2>Project Goal</h2>
      <p>The primary objective was to create an engaging and visually striking website that effectively conveys the thrilling essence of the Coulro Phobia experience. The site needed to attract, inform, and captivate visitors, offering them a glimpse into the world of 'Killer Clowns.'</p>
  
      <h2>Design and Development Insights</h2>
      <p>The website's design is centered around the theme of horror entertainment, with a focus on visual storytelling. It features a dark and intriguing color palette, with interactive elements to engage visitors. The layout is designed to be intuitive, allowing easy navigation through various sections like About, Characters, Portfolio, and Contact.</p>
  
      <h2>Key Features</h2>
      <ul>
        <li><strong>About:</strong> Providing background information on Coulro Phobia and its unique entertainment concept.</li>
        <li><strong>Characters:</strong> Showcasing the 'Killer Clowns' and other characters central to the Coulro Phobia experience.</li>
        <li><strong>Portfolio:</strong> Displaying a gallery of images and content related to past events and experiences.</li>
        <li><strong>Contact:</strong> Facilitating easy communication for inquiries and bookings.</li>
      </ul>
  
      <h2>Outcome and Impact</h2>
      <p>Since its launch, the Coulro Phobia – coulrosapien website has succeeded in creating an online presence that mirrors the thrilling and chilling nature of its real-world experience. It has attracted a significant audience, enhancing engagement and driving interest in the Coulro Phobia events.</p>
    `,
    img: require("@/assets/images/projects/coulrosapien.webp"),
  },

  {
    id: 13,
    title: "Ancientavenues - Religions Website",
    category: "WordPress Customization",
    tags: ["WordPress", "Client Project"],
    live_link: "https://ancientavenues.com",
    github_link: "",
    color: "#75070d",
    date: "12 March 2022",
    desc: `
      <h2>Project Overview</h2>
      <p><strong>Client:</strong> Ancientavenues<br>
         <strong>Industry:</strong> Religion and Spirituality<br>
         <strong>Platform:</strong> WordPress<br>
         <strong>Website:</strong> <a href='https://ancientavenues.com' target='_blank'>Ancientavenues</a></p>
      <p>Ancientavenues is a unique website dedicated to presenting the diverse religious landscape of the world. The website provides insightful data on the percentages of various religions globally, offering an informative and educational resource for users interested in spiritual demographics.</p>
  
      <h2>Project Goal</h2>
      <p>The goal of Ancientavenues was to create an informative platform that offers comprehensive data on world religions. The website was designed to be an educational tool for users to understand the distribution and prevalence of different faiths across the globe.</p>
  
      <h2>Design and Development Insights</h2>
      <p>The website’s design is focused on clarity and ease of access to information. It features an intuitive interface that allows users to easily navigate and interact with the religious data presented. The color scheme and layout were chosen to reflect the site's serious and educational nature.</p>
  
      <h2>Key Features</h2>
      <ul>
        <li><strong>Global Religion Data:</strong> Detailed statistics and percentages of various religions around the world.</li>
        <li><strong>Educational Resources:</strong> Informative content to provide users with deeper insights into different faiths.</li>
        <li><strong>User-Friendly Interface:</strong> Easy navigation and access to key data points and resources.</li>
        <li><strong>Interactive Elements:</strong> Dynamic features for engaging user interaction with the data.</li>
      </ul>
  
      <h2>Outcome and Impact</h2>
      <p>Since its inception, Ancientavenues has become a valuable resource for individuals seeking information on global religious demographics. The website has been acclaimed for its informative content, user-friendly design, and its contribution to the understanding of world religions.</p>
    `,
    img: require("@/assets/images/projects/ancientavenues.webp"),
  },

  {
    id: 14,
    title: "Jkanmanagement - An Internationally Accredited Company",
    category: "WordPress Customization",
    tags: ["WordPress", "Client Project"],
    live_link: "https://jkanmanagement.com/",
    github_link: "",
    color: "#747ecc",
    date: "05 August 2022",
    desc: `
      <h2>Project Overview</h2>
      <p><strong>Client:</strong> JKAN Music and Management<br>
         <strong>Industry:</strong> Entertainment and Music<br>
         <strong>Platform:</strong> WordPress<br>
         <strong>Website:</strong> <a href='https://jkanmanagement.com/' target='_blank'>Jkanmanagement</a></p>
      <p>JKAN Music and Management is dedicated to promoting Jamaican entertainment on the global stage. As a booking agency that has evolved into managing artists, brands, businesses, and campaigns, the website was designed to showcase the company's expansive range and its capabilities in conducting international business.</p>
  
      <h2>Project Goal</h2>
      <p>The aim was to create a website that effectively highlights the company's services and global reach. The site needed to present JKAN’s ability to manage a wide range of entertainment-related services, from artist bookings to marketing campaigns, in an attractive and professional manner.</p>
  
      <h2>Design and Development Insights</h2>
      <p>The website's design focuses on showcasing JKAN's range of services and its professional stature in the entertainment industry. The layout is user-friendly, allowing visitors to easily navigate through the various services offered, including artist booking and management consultation.</p>
  
      <h2>Key Features</h2>
      <ul>
        <li><strong>About:</strong> Providing detailed information about JKAN’s background and mission.</li>
        <li><strong>Services:</strong> Outlining the comprehensive services offered, including artist management and marketing campaigns.</li>
        <li><strong>Artist Booking and Management:</strong> Dedicated sections for booking and managing artists.</li>
        <li><strong>Portfolio:</strong> Showcasing previous successful projects and collaborations.</li>
        <li><strong>Contact:</strong> Enabling easy communication for potential clients and partners.</li>
      </ul>
  
      <h2>Outcome and Impact</h2>
      <p>Since its launch, the JKAN Music and Management website has been instrumental in enhancing the company's visibility and operational scope. The website effectively communicates the company’s expertise in the entertainment industry, leading to increased international engagement and business opportunities.</p>
    `,
    img: require("@/assets/images/projects/jkan_management.webp"),
  },

  {
    id: 15,
    title: "Advantageptr - Physical Therapy & Rehabilitation",
    category: "WordPress Customization",
    tags: ["WordPress", "Client Project"],
    live_link: "https://advantageptr.xyz/",
    github_link: "",
    color: "#51bce9",
    date: "10 October 2022",
    desc: `
      <h2>Project Overview</h2>
      <p><strong>Client:</strong> Advantage Physical Therapy & Rehabilitation<br>
         <strong>Industry:</strong> Healthcare<br>
         <strong>Platform:</strong> WordPress<br>
         <strong>Website:</strong> <a href='https://advantageptr.xyz/' target='_blank'>Advantage Physical Therapy & Rehabilitation</a></p>
      <p>Advantage Physical Therapy & Rehabilitation’s website is designed to exude a sense of peace and relief, mirroring the healing nature of their services. Utilizing calming shades of blue against a clean white background, the site communicates the serenity and effectiveness of their physical therapy and rehabilitation services.</p>
  
      <h2>Project Goal</h2>
      <p>The goal was to create a website that is both informative and soothing, reflecting the core values of pain relief and healing. The site aimed to facilitate easy access to information about services and provide quick communication features for immediate contact via call or WhatsApp.</p>
  
      <h2>Design and Development Insights</h2>
      <p>The website's design prioritizes user-friendliness and visual comfort. It features straightforward navigation to present the scope of services and includes quick action features for instant communication, enhancing the connection between prospective clients and the business.</p>
  
      <h2>Key Features</h2>
      <ul>
        <li><strong>About Us:</strong> Providing information about the practice’s philosophy and approach to physical therapy.</li>
        <li><strong>Our Services:</strong> Detailed descriptions of the physical therapy and rehabilitation services offered.</li>
        <li><strong>Book an Appointment:</strong> Easy online booking feature for client convenience.</li>
        <li><strong>Contact Us:</strong> Offering direct communication channels for inquiries and support.</li>
        <li><strong>Blog:</strong> Regularly updated content to engage users and provide valuable health insights.</li>
      </ul>
  
      <h2>Outcome and Impact</h2>
      <p>Since its launch, the Advantage Physical Therapy & Rehabilitation website has successfully established a serene online presence, aligning with their service ethos. The website has enhanced client interaction, supported the sales process, and contributed positively to the company’s profitability.</p>
    `,
    img: require("@/assets/images/projects/Advantageptr.webp"),
  },

  {
    id: 16,
    title: "Pastpost v1 - Figma to WordPress",
    category: "WordPress Development",
    tags: ["WordPress", "Elementor Widget Development", "Client Project"],
    live_link: "https://pastpost.com/",
    github_link: "",
    color: "#f5f1ee",
    date: "01 November 2022",
    desc: `
      <h2>Project Overview</h2>
      <p><strong>Client:</strong> Pastpost<br>
         <strong>Industry:</strong> Web Development<br>
         <strong>Platform:</strong> WordPress<br>
         <strong>Website:</strong> <a href='https://pastpost.com/' target='_blank'>Pastpost</a></p>
      <p>Pastpost v1 is a comprehensive project that demonstrates the seamless transition from Figma designs to WordPress, leveraging the power of Elementor widgets. This project included developing custom Elementor widgets and plugins to ensure a dynamic and engaging user experience.</p>
  
      <h2>Project Goal</h2>
      <p>The goal was to convert detailed Figma designs into a fully functional WordPress site, maintaining the design integrity while leveraging the flexibility of WordPress.</p>
  
      <h2>Design and Development Insights</h2>
      <p>The project involved a meticulous process of creating Elementor widgets that aligned with the Figma designs, developing custom plugins for enhanced functionality and user engagement.</p>
  
      <pre class="bg-gray-200"><code >
  Design Inspiration (Figma): <a href='https://www.figma.com/file/04Eiq4vkRiWoomVgO95fDq/Past-Post-Design?type=design&node-id=0%3A1&mode=design&t=GYINr6tQBRsjEHqn-1' target='_blank'>View Design</a>
  Elementor Widgets Custom Design (GitHub): <a href='https://github.com/md-arif-islam/pastpost-elementor-widgets-custom-design' target='_blank'>View Repository</a>
  Elementor Plugin (GitHub): <a href='https://github.com/md-arif-islam/wp-pastpost-elementor-plugin' target='_blank'>View Repository</a>
  Companion Plugin (GitHub): <a href='https://github.com/md-arif-islam/wp-pastpost-companion-plugin' target='_blank'>View Repository</a>
      </code></pre>
  
      <h2>Key Features</h2>
      <ul>
        <li><strong>Custom Elementor Widgets:</strong> Tailored to match the original design, enhancing site functionality and user interaction.</li>
        <li><strong>Plugin Development:</strong> Custom plugins for managing content and extending site capabilities.</li>
        <li><strong>Responsive Design:</strong> Ensuring a seamless experience across various devices.</li>
      </ul>
  
      <h2>Outcome and Impact</h2>
      <p>The project successfully bridged the gap between design and functionality, resulting in a WordPress site that is both aesthetically pleasing and highly functional. It stands as a testament to the effective use of Elementor and custom plugins in web development.</p>
    `,
    img: require("@/assets/images/projects/pastpost_v1.webp"),
  },

  {
    id: 16.1,
    title: "Pastpost v2 - Figma to Wordpress",
    category: "WordPress Development",
    tags: ["WordPress", "Elementor Widget Development", "Client Project"],
    live_link: "https://pastpost.com/",
    github_link: "",
    color: "#f5f1ee",
    date: "20 April 2023",
    desc: `
      <h2>Project Overview</h2>
      <p><strong>Client:</strong> Pastpost<br>
         <strong>Industry:</strong> Web Development<br>
         <strong>Platform:</strong> WordPress<br>
         <strong>Website:</strong> <a href='https://pastpost.com/' target='_blank'>Pastpost v2</a></p>
      <p>The Pastpost v2 project entailed an advanced transformation from Figma designs to a WordPress site, utilizing the capabilities of Elementor widget development. This iteration aimed to enhance the website with new features, an updated design, and improved functionality.</p>
  
      <h2>Project Goal</h2>
      <p>The objective was to upgrade the existing Pastpost website, incorporating new design elements from Figma and expanding its functionalities using custom Elementor widgets. The focus was on improving user experience and adding contemporary features that reflect the evolving needs of the client.</p>
  
      <h2>Design and Development Insights</h2>
      <p>The development process was centered around implementing new design concepts from Figma, translating these into a responsive and interactive WordPress theme. Advanced Elementor widget development was key to adding new features and enhancing the site’s capabilities.</p>
  
      <pre class="bg-gray-200"><code >
  Design Inspiration (Figma): <a href='https://www.figma.com/file/04Eiq4vkRiWoomVgO95fDq/Past-Post-Design?type=design&node-id=0%3A1&mode=design&t=GYINr6tQBRsjEHqn-1' target='_blank'>View Design</a>
  Elementor Widgets Custom Design (GitHub): <a href='https://github.com/md-arif-islam/pastpost-elementor-widgets-custom-design' target='_blank'>View Repository</a>
  Elementor Plugin (GitHub): <a href='https://github.com/md-arif-islam/wp-pastpost-elementor-plugin' target='_blank'>View Repository</a>
  Companion Plugin (GitHub): <a href='https://github.com/md-arif-islam/wp-pastpost-companion-plugin' target='_blank'>View Repository</a>
      </code></pre>
  
      <h2>Key Features</h2>
      <ul>
        <li><strong>Updated Elementor Widgets:</strong> Newly developed widgets enhancing the site's interactivity and design.</li>
        <li><strong>Improved User Interface:</strong> A focus on user experience with an updated, responsive design.</li>
        <li><strong>Advanced Functionalities:</strong> New features and capabilities to meet the latest web standards and client needs.</li>
      </ul>
  
      <h2>Outcome and Impact</h2>
      <p>The revamped Pastpost v2 site successfully integrates the latest design trends with functional enhancements. It showcases the flexibility of WordPress and Elementor in adapting to new design paradigms, resulting in a modern, efficient, and user-friendly website.</p>
    `,
    img: require("@/assets/images/projects/pastpost_v2.webp"),
  },

  {
    id: 17,
    title: "Precision Law - Jamaican Client Project",
    category: "WordPress Customization",
    tags: ["WordPress", "Client Project"],
    live_link: "https://precisionlaw.org/",
    github_link: "",
    color: "#d09976",
    date: "27 June 2022",
    desc: `
      <h2>Project Overview</h2>
      <p><strong>Client:</strong> Precision Law<br>
         <strong>Industry:</strong> Legal Services<br>
         <strong>Platform:</strong> WordPress<br>
         <strong>Website:</strong> <a href='https://precisionlaw.org/' target='_blank'>Precision Law</a></p>
      <p>Precision Law, a forward-thinking Jamaican law firm, sought to create a website that could both promote growth and evolve alongside the firm. The website features a unique log of achievements and an updatable experience section tailored to various areas of legal practice. The redesign also involved a brand identity refresh, utilizing colors that convey safety, confidence, and experience.</p>
  
      <h2>Project Goal</h2>
      <p>The aim was to develop a website that not only serves as an effective tool for firm growth but also remains adaptable as the firm expands its services. Key features like achievement logging and practice area updates were prioritized to reflect the firm's dynamic nature and expertise.</p>
  
      <h2>Design and Development Insights</h2>
      <p>The website design emphasizes professionalism and confidence, aligning with the firm’s branding. It incorporates user-friendly interfaces and quick booking features, simplifying client engagement and payment processes, thereby enhancing user experience.</p>
  
      <h2>Key Features</h2>
      <ul>
        <li><strong>About Us:</strong> Providing information about the firm's background and mission.</li>
        <li><strong>Our Offerings:</strong> Detailed overview of the legal services and specialties offered.</li>
        <li><strong>Contact Us:</strong> A direct channel for client inquiries and consultations.</li>
        <li><strong>Quick Booking:</strong> Simplified booking and payment system for client convenience.</li>
        <li><strong>My Account:</strong> Personal account area for clients to manage their interactions and appointments.</li>
      </ul>
  
      <h2>Outcome and Impact</h2>
      <p>Since its launch, Precision Law’s website has played a crucial role in enhancing the firm's online presence and client engagement. The website effectively communicates the firm’s expertise and commitment to providing top-tier legal services, resulting in increased client interactions and firm growth.</p>
    `,
    img: require("@/assets/images/projects/precisionlaw.webp"),
  },

  {
    id: 18,
    title: "Totallyunsigned - A Artist Website",
    category: "WordPress Customization",
    tags: ["WordPress", "Client Project"],
    live_link: "https://totallyunsigned.com/",
    github_link: "",
    color: "#0b83ba",
    date: "01 October 2022",
    desc: `
      <h2>Project Overview</h2>
      <p><strong>Client:</strong> Totallyunsigned<br>
         <strong>Industry:</strong> Arts and Entertainment<br>
         <strong>Platform:</strong> WordPress<br>
         <strong>Website:</strong> <a href='https://totallyunsigned.com/' target='_blank'>Totallyunsigned</a></p>
      <p>Totallyunsigned is an artist-centric website aimed at showcasing a diverse array of talents across multiple artistic domains. The project is a comprehensive platform for artists, including musicians, actors, photographers, and sculptors, to display their work and gain visibility.</p>
  
      <h2>Project Goal</h2>
      <p>The goal was to create a dynamic and engaging online space that caters to various artists and art forms. The website is designed to be a hub for both established and emerging talents, providing them a platform to share their works and connect with audiences and industry professionals.</p>
  
      <h2>Design and Development Insights</h2>
      <p>Focused on user experience, the design of Totallyunsigned combines aesthetic appeal with functionality. The website is organized into various sections, each dedicated to a different form of art, allowing for easy navigation and interaction. The site's color scheme and layout are tailored to enhance the visual appeal of the artists' work.</p>
  
      <h2>Key Features</h2>
      <ul>
        <li><strong>All Artists:</strong> A comprehensive directory of all artists featured on the site.</li>
        <li><strong>Latest From [Art, Others, Actors, Athletes, Musicians, Photographers, Sculpture Artists]:</strong> Updated sections showcasing recent works and news from various artistic fields.</li>
        <li><strong>Share Your Works:</strong> A platform for artists to submit and showcase their creations.</li>
        <li><strong>Contact Us:</strong> A direct channel for inquiries and interaction with the website administrators or artists.</li>
        <li><strong>My Account/Registration:</strong> Personalized user accounts for artists and visitors to manage their interaction with the site.</li>
      </ul>
  
      <h2>Outcome and Impact</h2>
      <p>Though still in the development phase, Totallyunsigned is poised to become a significant platform for artists from diverse backgrounds. It aims to foster a vibrant community where creativity is showcased and appreciated, enabling artists to grow their audience and professional network.</p>
    `,
    img: require("@/assets/images/projects/totallyunsigned.webp"),
  },

  {
    id: 19,
    title: "Xingfa - Figma to WordPress",
    category: "WordPress Development",
    tags: ["WordPress", "Client Project"],
    live_link: "https://jf-facadeglass.com/",
    github_link: "",
    color: "#0b83ba",
    date: "27 November 2022",
    desc: `
      <h2>Project Overview</h2>
      <p><strong>Client:</strong> Xingfa<br>
         <strong>Industry:</strong> Manufacturing<br>
         <strong>Platform:</strong> WordPress<br>
         <strong>Website:</strong> <a href='https://jf-facadeglass.com/' target='_blank'>Xingfa</a></p>
      <p>Xingfa's project involved a meticulous transformation from Figma design wireframes to a fully functional WordPress website. Utilizing custom Elementor widgets and plugins, the site was developed to offer a seamless and engaging user experience, aligning with the high standards of Xingfa's manufacturing business.</p>
  
      <h2>Project Goal</h2>
      <p>The objective was to create an intuitive, responsive WordPress site from Figma designs, focusing on showcasing Xingfa's manufacturing expertise. The project aimed to enhance the digital presence of Xingfa with a modern, efficient, and user-friendly website.</p>
  
      <h2>Design and Development Insights</h2>
      <p>Emphasis was placed on converting the Figma wireframes into a dynamic WordPress theme using Elementor. This involved custom widget development to ensure design fidelity and to introduce advanced functionalities for a better user experience.</p>
  
      <pre class="bg-gray-200"><code >
  Design Inspiration (Figma): <a href='https://www.figma.com/file/Yeuedbc1vJy4IkHmObSjni/xing-fa-wirefram?type=design&mode=design&t=GYINr6tQBRsjEHqn-1' target='_blank'>View Design</a>
  Elementor Widgets Custom Design (GitHub): <a href='https://github.com/md-arif-islam/xingfa-elementor-widgets-custom-design' target='_blank'>View Repository</a>
  Elementor Plugin (GitHub): <a href='https://github.com/md-arif-islam/wp-xingfa-elementor-plugin' target='_blank'>View Repository</a>
  Companion Plugin (GitHub): <a href='https://github.com/md-arif-islam/wp-xingfa-companion-plugin' target='_blank'>View Repository</a>
      </code></pre>
  
      <h2>Key Features</h2>
      <ul>
        <li><strong>Custom Elementor Widgets:</strong> Developed to replicate the Figma designs accurately, enhancing site interactivity and visual appeal.</li>
        <li><strong>Responsive Design:</strong> Ensuring the website is accessible and functional across various devices and screen sizes.</li>
        <li><strong>Advanced Functionality:</strong> Integrating custom plugins for additional website capabilities.</li>
      </ul>
  
      <h2>Outcome and Impact</h2>
      <p>The Xingfa website stands as a testament to the successful integration of design and functionality in web development. The project showcases the capability to bring intricate designs to life, resulting in a contemporary, efficient, and user-friendly website for Xingfa's manufacturing business.</p>
    `,
    img: require("@/assets/images/projects/xingfa.webp"),
  },
  {
    id: 20,
    title: "Sugarly Jamaica - Jamaican Client Project",
    category: "WordPress Customization",
    tags: ["WordPress", "Client Project"],
    live_link: "https://sugarlyja.com/",
    github_link: "",
    color: "#df7e9b",
    date: "18 July 2022",
    desc: `
      <h2>Project Overview</h2>
      <p><strong>Client:</strong> Sugarly Jamaica<br>
         <strong>Industry:</strong> Beauty and Personal Care<br>
         <strong>Platform:</strong> WordPress<br>
         <strong>Website:</strong> <a href='https://sugarlyja.com/' target='_blank'>Sugarly Jamaica</a></p>
      <p>Sugarly Jamaica is a vibrant and inviting website focused on introducing and promoting a novel hair removal technique called sugaring. The site features the brand's signature magenta and sunflower yellow colors, creating a festive and attractive online presence. The project includes e-commerce capabilities, a first purchase discount offer, and showcases compelling product imagery.</p>
  
      <h2>Project Goal</h2>
      <p>The goal was to design a website that effectively communicates the unique aspects of sugaring, a lesser-known hair removal method. The site aimed to not only inform visitors about sugaring but also to incentivize them to try the service through attractive offers and an easy online shopping experience.</p>
  
      <h2>Design and Development Insights</h2>
      <p>The design of the Sugarly Jamaica website is centered around the vibrant colors of the brand. The layout is designed to be user-friendly and visually appealing, highlighting the benefits of sugaring. The e-commerce functionality is seamlessly integrated to enable easy purchase and customer interaction.</p>
  
      <h2>Key Features</h2>
      <ul>
        <li><strong>About Sugarly:</strong> Providing background information about the company and its sugaring technique.</li>
        <li><strong>Our Products:</strong> Displaying a range of products with detailed information.</li>
        <li><strong>Contact Us:</strong> Offering a direct line of communication for customer inquiries and support.</li>
        <li><strong>Cart and Checkout:</strong> Streamlined shopping cart and checkout process for easy online purchases.</li>
        <li><strong>My Account:</strong> Personalized customer account area for managing orders and preferences.</li>
      </ul>
  
      <h2>Outcome and Impact</h2>
      <p>Since its launch, the Sugarly Jamaica website has successfully attracted attention to the brand and its unique hair removal method. The festive and engaging design, along with the functional e-commerce setup, has increased product visibility and sales, enhancing the brand's market presence in the beauty industry.</p>
    `,
    img: require("@/assets/images/projects/sugarlyja.webp"),
  },

  {
    id: 21,
    title: "BoxoSox - Jamaican Client Project",
    category: "WordPress Customization",
    tags: ["WordPress", "Client Project"],
    live_link: "https://boxosox.club/",
    github_link: "",
    color: "#d4bc26",
    date: "23 July 2022",
    desc: `
      <h2>Project Overview</h2>
      <p><strong>Client:</strong> BoxoSox<br>
         <strong>Industry:</strong> Fashion and Retail<br>
         <strong>Platform:</strong> WordPress<br>
         <strong>Website:</strong> <a href='https://boxosox.club/' target='_blank'>BoxoSox</a></p>
      <p>BoxoSox, a Jamaican fashion brand, is synonymous with premium, exclusive, and trendy sock designs. The project involved developing a website that encapsulates the brand's ethos, complemented by professional photography to vividly showcase the products. Additionally, the website features an e-commerce store with an affiliate marketing program for global product distribution.</p>
  
      <h2>Project Goal</h2>
      <p>The primary goal was to create a website that not only mirrors the premium nature of BoxoSox's products but also facilitates easy online shopping and worldwide distribution through an affiliate program. The site was aimed at fashion-conscious consumers seeking quality and exclusivity in their choices.</p>
  
      <h2>Design and Development Insights</h2>
      <p>Special focus was given to the website's design, ensuring it reflects BoxoSox's high-caliber brand identity. The clean design style, coupled with professional product photography, effectively showcases the brand's premium characteristics. The site incorporates e-commerce functionalities for easy purchase and distribution of products.</p>
  
      <h2>Key Features</h2>
      <ul>
        <li><strong>About BoxoSox:</strong> Introducing the brand's philosophy and offerings.</li>
        <li><strong>Affiliate Programs:</strong> Details of the affiliate program for wider distribution.</li>
        <li><strong>Celebrate Jamaica:</strong> Special collection celebrating Jamaican culture and fashion.</li>
        <li><strong>Shop Collections:</strong> Showcasing various sock collections with a focus on style and quality.</li>
        <li><strong>Why Join BoxoSox.club?:</strong> Highlighting the benefits of joining the BoxoSox community.</li>
        <li><strong>Contact Us:</strong> Providing easy ways for customers to connect and inquire.</li>
      </ul>
  
      <h2>Outcome and Impact</h2>
      <p>Since its inception, the BoxoSox website has been pivotal in elevating the brand's online presence. It successfully communicates the premium nature of the products, thereby attracting a wider audience and increasing sales through both direct purchases and affiliate marketing channels.</p>
    `,
    img: require("@/assets/images/projects/boxocox.webp"),
  },

  {
    id: 22,
    title: "Moodies Auto - Jamaican Client Project",
    category: "WordPress Customization",
    tags: ["WordPress", "Client Project"],
    live_link: "https://moodiesauto.com/",
    github_link: "",
    color: "#626796",
    date: "28 May 2022",
    desc: `
      <h2>Project Overview</h2>
      <p><strong>Client:</strong> Moodies Auto<br>
         <strong>Industry:</strong> Automotive<br>
         <strong>Platform:</strong> WordPress<br>
         <strong>Website:</strong> <a href='https://moodiesauto.com/' target='_blank'>Moodies Auto</a></p>
      <p>Moodies Auto, a leading automotive business in Jamaica, sought to establish an online presence that reflects their expertise in the industry. This project involved developing a sophisticated, user-friendly website to showcase their services and engage with customers effectively.</p>
  
      <h2>Project Goal</h2>
      <p>The aim was to create a digital platform that not only represents the professional image of Moodies Auto but also provides comprehensive information about their automotive services. The website needed to be intuitive, informative, and accessible, catering to automotive enthusiasts and customers in Jamaica.</p>
  
      <h2>Design and Development Insights</h2>
      <p>Special attention was given to designing a site that balances aesthetics with functionality. The website was developed on WordPress, with a focus on easy navigation, clear information display, and a professional look that mirrors the quality of Moodies Auto's services.</p>
  
      <h2>Key Features</h2>
      <ul>
        <li><strong>Home Page:</strong> The main landing page introducing Moodies Auto and its primary services.</li>
        <li><strong>About Us:</strong> Detailed information about the company's history and expertise in the automotive field.</li>
        <li><strong>Our Offerings:</strong> A comprehensive overview of the services provided by Moodies Auto.</li>
        <li><strong>Contact:</strong> Easy-to-navigate contact page for customer inquiries and service requests.</li>
        <li><strong>NG Racing:</strong> A special section dedicated to showcasing their racing-related services and events.</li>
      </ul>
  
      <h2>Outcome and Impact</h2>
      <p>Since its launch, Moodies Auto's website has been instrumental in enhancing their brand visibility and customer engagement. The platform has effectively communicated the company's services and commitment to quality, leading to increased customer interaction and business growth.</p>
    `,
    img: require("@/assets/images/projects/moodies_auto.webp"),
  },

  {
    id: 23,
    title: "My Hydrogen Water Bottle - US Client Project",
    category: "WordPress Customization",
    tags: ["WordPress", "Ecommerce", "Client Project"],
    live_link: "https://myhydrogenwaterbottle.com/",
    github_link: "",
    color: "#7aa2ab",
    date: "30 August 2022",
    desc: `
      <h2>Project Overview</h2>
      <p><strong>Client:</strong> My Hydrogen Water Bottle<br>
      <strong>Industry:</strong> Water Bottle, E-commerce<br>
         <strong>Platform:</strong> WordPress<br>
         <strong>Website:</strong> <a href='https://myhydrogenwaterbottle.com/' target='_blank'>My Hydrogen Water Bottle</a></p>
      <p>My Hydrogen Water Bottle is a pioneering US-based project focusing on health and wellness products, particularly hydrogen water bottles using the latest SPE PEM technology. This project included creating a modern, user-friendly e-commerce website to promote and sell their advanced hydrogen water bottles.</p>
  
      <h2>Project Goal</h2>
      <p>The primary objective was to develop an online platform that effectively showcases the health benefits of hydrogen water bottles and facilitates easy online purchasing. The website was designed to cater to health-conscious consumers, emphasizing the unique features of their products.</p>
  
      <h2>Design and Development Insights</h2>
      <p>The website's design focused on a clean, informative, and user-friendly interface. Emphasis was placed on highlighting the product's technological advantages and health benefits. The development involved integrating e-commerce functionalities with a focus on a seamless shopping experience.</p>
  
      <div class='embed-container'>
      <iframe src='https://player.vimeo.com/video/817377965' frameborder='0' allow='autoplay; fullscreen' allowfullscreen></iframe>
    </div>

      <h2>Key Features</h2>
      <ul>
        <li><strong>Home Page:</strong> Introducing the hydrogen water bottle and its benefits.</li>
        <li><strong>About Us:</strong> Sharing the vision and mission of My Hydrogen Water Bottle.</li>
        <li><strong>Products:</strong> Showcasing the range of hydrogen water bottles with detailed descriptions.</li>
        <li><strong>Benefits of Hydrogen:</strong> Educating visitors on the health benefits of hydrogen-rich water.</li>
        <li><strong>Blog:</strong> Regularly updated content to engage users and provide valuable health insights.</li>
        <li><strong>Contact Us:</strong> Offering easy ways for customers to get in touch with questions or support needs.</li>
      </ul>
  
      <h2>Outcome and Impact</h2>
      <p>Since its launch, the My Hydrogen Water Bottle website has received positive feedback for its comprehensive presentation of products and ease of use. The site effectively communicates the health benefits of hydrogen water, thereby enhancing the brand's online presence and sales.</p>
    `,
    img: require("@/assets/images/projects/myhydrogenwaterbottle-ecommerce.webp"),
  },

  {
    id: 24,
    title: "Chamber Hydro Farm - Jamaican Client Project",
    category: "WordPress Customization",
    tags: ["WordPress", "Ecommerce", "Client Project"],
    live_link: "https://chambershydrofarm.com/",
    github_link: "",
    color: "#7aa2ab",
    date: "27 May 2022",
    desc: `
    <h2>Project Overview</h2>
    <p><strong>Client:</strong> Chamber Hydro Farm<br>
       <strong>Industry:</strong> Agriculture, E-commerce<br>
       <strong>Platform:</strong> WordPress with WooCommerce<br>
       <strong>Website:</strong> <a href='https://chambershydrofarm.com/' target='_blank'>Chamber Hydro Farm</a></p>
  
       <p>Chamber Hydro Farm, led by business owner Ricardo Chambers, is at the forefront of revolutionizing the agriculture industry in Jamaica. The project involved creating a modern website that reflects the innovative spirit of Chamber Hydro Farm, integrating a local e-commerce store to showcase their advancements in propagation techniques, containerized farming, and hydroponics.</p>
    <h2>Project Goal</h2>
    <p>The goal was to develop a user-friendly, visually appealing website that not only highlights the unique agricultural methods of Chamber Hydro Farm but also provides an integrated platform for e-commerce. The site aims to connect with a broad audience, from agricultural enthusiasts to casual visitors, and offer an engaging and informative online shopping experience.</p>
  
    <h2>Design and Development Insights</h2>
    <p>The website was developed with a focus on UX and UI design, ensuring a responsive and intuitive user experience, aligning with the themes of agriculture and sustainability.</p>
  
    <div class='embed-container'>
      <iframe src='https://player.vimeo.com/video/817375624' frameborder='0' allow='autoplay; fullscreen' allowfullscreen></iframe>
    </div>

    <h2>Key Features</h2>
    <ul>
      <li><strong>Home Page:</strong> Introduces visitors to the farm and its products.</li>
      <li><strong>About Us:</strong> Details the story and values of Chamber Hydro Farm.</li>
      <li><strong>Gallery:</strong> A visual display of the farm and its operations.</li>
      <li><strong>Our Offerings:</strong> Information on products and services offered.</li>
      <li><strong>Visit the Farm:</strong> Invites users to experience the farm.</li>
      <li><strong>Shop Online:</strong> E-commerce platform for product purchasing.</li>
      <li><strong>Contact Us:</strong> Provides contact information and engagement methods.</li>
    </ul>
  
    <h2>Outcome and Impact</h2>
    <p>The website has been well-received for its design and functionality, enhancing Chamber Hydro Farm's online presence and accessibility in the agriculture sector.</p>
    `,
    img: require("@/assets/images/projects/Chambers-Hydrofarm-Ecommerce.webp"),
  },
  {
    id: 25,
    title: "Tommy Teas - US Client Project",
    category: "WordPress Customization",
    tags: ["WordPress", "Ecommerce", "Client Project"],
    live_link: "https://tommyteas.com/",
    github_link: "",
    color: "#7aa2ab",
    date: "16 September 2022",
    desc: `
    
    <h2>Project Overview</h2>
    <p><strong>Client:</strong> Based in the United States<br>
       <strong>Industry:</strong> E-commerce (Tea Sales)<br>
       <strong>Platform:</strong> WordPress with WooCommerce<br>
       <strong>Website:</strong> <a href='https://tommyteas.com/' target='_blank'>Tommy Teas</a></p>
    
       <h2>Project Goal</h2> 
       <p>To design a modern, user-friendly e-commerce website specialized in selling a variety of teas, enhancing the shopping experience for tea enthusiasts.</p>
    <p>A WordPress-based ecommerce project, 'Tommy Teas' integrates a variety of functionalities to enhance the user experience, focusing on a tea-centric audience. This project demonstrates a dynamic and customer-centric approach to online tea retail, melding contemporary design with efficient e-commerce solutions.</p>

    <h2>Design and Development Insights</h2>
    <p>The primary objective was to craft a visually appealing and intuitive interface tailored to tea lovers. Emphasis was placed on the user experience (UX) design to ensure smooth navigation, engaging content presentation, and an effortless shopping process from product discovery to checkout.</p>
    
    <h2>Key Features</h2>
    <ul>
      <li><strong>Home Page:</strong> Designed using Elementor, the front page introduces visitors to the site with a welcoming design, showcasing handpicked teas and exclusive offers.</li>
      <li><strong>About Us:</strong> This page delves into the brand's story, values, and dedication to quality teas.</li>
      <li><strong>Blog:</strong> A dedicated area for sharing tea-related content, advice, and news, fostering community engagement.</li>
      <li><strong>Product Catalog:</strong> A comprehensive range of teas, neatly organized and detailed with rich descriptions and imagery.</li>
      <li><strong>Interactive Elements:</strong> Incorporation of sliders, carousels, and dynamic content sections to boost user interaction and interest.</li>
    </ul>

    <h2>Outcome and Impact</h2>
    <p>'Tommy Teas' has been acclaimed for its intuitive design, user-friendly interface, and extensive selection of teas. The project effectively merged visual appeal with practical functionality, positioning 'Tommy Teas' as a preferred site for tea enthusiasts.</p>
    
    
  
    `,
    img: require("@/assets/images/projects/tommy_teas.webp"),
  },

  {
    id: 26,
    title: "React Portfolio - Practice Project",
    category: "React JS",
    tags: ["React", "Practice Project"],
    live_link: "https://react-portfolio-by-me.vercel.app/",
    github_link: "https://github.com/md-arif-islam/react-portfolio",
    color: "#022c43",
    date: "13 Octobor 2022",
    desc: `
    <h1>React Portfolio - Practice Project</h1>
        <p>A dynamic and interactive portfolio site developed as a practice project in React JS. This portfolio showcases a modern design approach, integrating various frontend technologies and libraries to create an engaging user experience.</p>
        
        <h2>Core Features</h2>
        <ul>
            <li>Interactive UI elements with engaging animations using GSAP and Animate.css.</li>
            <li>Integration of Leaflet for interactive mapping capabilities.</li>
            <li>Effective use of React Router for smooth navigation between pages.</li>
        </ul>

        <h2>Technical Aspects</h2>
        <p>The project leverages React's component-based architecture, allowing for a modular and scalable design. It incorporates Firebase for backend functionality and uses EmailJS for handling email communications directly from the portfolio.</p>
        
        <h3>Development Stack</h3>
        <ul>
            <li>React JS for building a responsive and dynamic user interface.</li>
            <li>Firebase for database and backend services.</li>
            <li>Font Awesome for adding visually appealing icons.</li>
            <li>SASS for advanced styling and design customization.</li>
        </ul>

        <h3>Libraries and Frameworks</h3>
        <ul>
            <li>GSAP and Animate.css for smooth and attractive animations.</li>
            <li>Leaflet and React-Leaflet for integrating interactive maps.</li>
            <li>React Loaders for engaging loading animations.</li>
        </ul>

        <a href='https://github.com/md-arif-islam/react-portfolio' target='_blank'>View on GitHub</a>
    `,
    img: require("@/assets/images/projects/react-portfolio.webp"),
  },

  {
    id: 27,
    title: "Pickrides Fontend - Figma to HTML",
    category: "HTML Template",
    tags: ["Figma", "HTML", "Client Project"],
    live_link: "https://md-arif-islam.github.io/pickrides-fontend/",
    github_link: "",
    color: "#022c43",
    date: "01 Octobor 2022",
    desc: `
    
        <p>A responsive and visually appealing HTML template, 'Pickrides Frontend' was developed as a client project, focusing on converting a Figma design into a functional HTML layout. This project demonstrates a rapid development process, creating a polished and user-friendly interface with Bootstrap for a ride-sharing service website.</p>
        
        <h2>Design Conversion</h2>
        <p>The project involved meticulously converting a Figma design into a live HTML template. The design, available <a href='https://www.figma.com/file/jNsNIB5SdAUu5rihi56y6M/Pickrides?type=design&node-id=0%3A1&mode=design&t=CRKlIir5NGSywJwy-1' target='_blank'>here</a>, was transformed into a web-ready format, maintaining the aesthetic and functional aspects of the original design.</p>

        <h2>Core Features</h2>
        <ul>
            <li>Three distinct HTML pages, each catering to different aspects of the ride-sharing service.</li>
            <li>Responsive design ensuring usability across various devices and screen sizes.</li>
            <li>Integration of Bootstrap framework for rapid and efficient UI development.</li>
        </ul>

        <h2>Technical Aspects</h2>
        <p>The template was developed with an emphasis on responsiveness and cross-browser compatibility. Bootstrap's grid system and components were utilized to achieve a clean, modern look, consistent with the original Figma design.</p>

        <h3>Project Outcome</h3>
        <p>This project stands as an example of efficient design-to-code conversion, highlighting proficiency in HTML development and Bootstrap framework. It showcases the ability to deliver client projects with speed, without compromising on quality and design fidelity.</p>
        
      
    `,
    img: require("@/assets/images/projects/pickrides-fontend.webp"),
  },

  {
    id: 28,
    title: "Next Js Portfolio - Practice Project",
    category: "React JS",
    tags: ["HTML", "Client Project"],
    live_link: "https://next-js-portfolio-arif.vercel.app/",
    github_link: "https://github.com/md-arif-islam/react-portfolio-next",
    color: "#111827",
    date: "31 Octobor 2022",
    desc: `
    
    <h1>Next Js Portfolio - Practice Project</h1>
    <p>This project is a sleek and modern portfolio, built using Next.js, showcasing a blend of creativity and functionality. Designed as a practice project, it serves as an excellent example of a client-focused portfolio site, demonstrating proficiency in React and modern web development practices.</p>
    
    <h2>Core Features</h2>
    <ul>
        <li>Responsive and visually appealing layout, ideal for showcasing portfolio work.</li>
        <li>Efficient navigation and dynamic content rendering using Next.js features.</li>
        <li>Integration of React Icons for enhanced visual appeal and user interface design.</li>
    </ul>

    <h2>Technical Aspects</h2>
    <p>This portfolio leverages Next.js for its server-side rendering capabilities, providing a fast and SEO-friendly user experience. React.js forms the backbone of the application, enabling dynamic and interactive UI components.</p>
    
    <h3>Development Stack</h3>
    <ul>
        <li>Next.js as the React framework, offering features like server-side rendering and optimized loading.</li>
        <li>React.js for building user interfaces with reusable components.</li>
        <li>Tailwind CSS for styling, ensuring a modern and responsive design.</li>
        <li>React Icons for adding scalable and customizable icons to the application.</li>
    </ul>
    <a href='https://github.com/md-arif-islam/react-portfolio-next' target='_blank'>View on GitHub</a>
    `,
    img: require("@/assets/images/projects/next-js-portfolio.webp"),
  },

  {
    id: 29,
    title: "Various Laravel Project",
    category: "Laravel",
    tags: [
      "Laravel",
      "Laravel UI",
      "Auth",
      "API",
      "Nuxt.js",
      "Vue.js",
      "CRUD",
      "Laravel Sanctum",
      "Guzzle",
      "Doctrine DBAL",
      "Laravel Tinker",
      "Livewire",
      "E-commerce",
      "Shopping Cart",
      "Laravel Breeze",
      "Backend Development",
    ],
    live_link: "",
    github_link: "",
    color: "#e6281c",
    date: "", // Add the date
    desc: `
    <h1>Authentication with Laravel UI</h1>
    <p>This project highlights the implementation of a user authentication system using Laravel UI. It exemplifies the ease and efficiency of managing user authentication and registration within the Laravel ecosystem.</p>
    
    <h2>Core Features</h2>
    <ul>
        <li>User login and registration with Laravel UI.</li>
        <li>Secure session management using Laravel's built-in capabilities.</li>
        <li>Integration with frontend components for a seamless user experience.</li>
    </ul>

    <h2>Technical Aspects</h2>
    <p>The application leverages Laravel Sanctum for API security, Guzzle for handling HTTP requests, and Laravel UI for user interface components. These tools collectively enhance the functionality and security of the application.</p>
    
    <h3>Development Stack</h3>
    <ul>
        <li>Laravel Framework as the core backend technology.</li>
        <li>Laravel Sanctum for API token authentication.</li>
        <li>Guzzle for efficient HTTP request handling.</li>
    </ul>
        <a href='https://github.com/md-arif-islam/authenticate-with-laravel-ui' target='_blank'>View on GitHub</a>
        <img src='${require("@/assets/images/projects/laravel-projects/authenticate-with-laravel-ui.gif")}' alt='Authentication with Laravel UI'>
        <hr>

        <h1>Mini CMS with Laravel</h1>
        <p>A Mini CMS project, this application demonstrates Laravel's capability to create dynamic content management systems. It's a perfect example of Laravel's flexibility in developing content-focused web applications.</p>
        
        <h2>Core Features</h2>
        <ul>
            <li>Dynamic content creation and management.</li>
            <li>Intuitive content editing and organization.</li>
            <li>Robust database interactions using Doctrine DBAL.</li>
        </ul>

        <h2>Technical Aspects</h2>
        <p>Utilizing Laravel's powerful ORM and Doctrine DBAL, the application offers a robust platform for managing content. Laravel Tinker provides an interactive shell for direct interaction with the Laravel framework.</p>
        
        <h3>Development Stack</h3>
        <ul>
            <li>Laravel Framework for overall application structure.</li>
            <li>Doctrine DBAL for advanced database interactions.</li>
            <li>Laravel Tinker for enhanced development and testing.</li>
        </ul>
        <a href='https://github.com/md-arif-islam/mini-cms-with-laravel' target='_blank'>View on GitHub</a>

        <img src='${require("@/assets/images/projects/laravel-projects/mini-cms-with-laravel.gif")}' alt='Mini CMS with Laravel'>

        <hr/>

        <h1>Laravel API Project</h1>
        <p>This project showcases the creation of a robust API using Laravel, demonstrating Laravel's capabilities in backend development and API construction. It's a perfect example of using Laravel for building scalable and efficient APIs.</p>
        
        <h2>Core Features</h2>
        <ul>
            <li>Efficient API development with Laravel.</li>
            <li>Implementation of best practices in API security and performance.</li>
            <li>Use of Laravel Sanctum for token-based authentication.</li>
        </ul>

        <h2>Technical Aspects</h2>
        <p>Developed using Laravel, this project takes advantage of its powerful ORM and middleware to create a secure and efficient API. Key tools include Laravel Sanctum for security, Guzzle for HTTP requests, and Laravel Tinker for an interactive development environment.</p>
        
        <h3>Development Stack</h3>
        <ul>
            <li>Laravel Framework for backend and API development.</li>
            <li>Laravel Sanctum for secure token-based authentication.</li>
            <li>Guzzle for effective HTTP request management.</li>
        </ul>
        <a href='https://github.com/md-arif-islam/laravel-api-project' target='_blank'>View on GitHub</a>
        <img src='${require("@/assets/images/projects/laravel-projects/laravel-api-project.png")}' alt='Laravel API Project'>

        <hr/>

        <h1>Laravel & Nuxt Js Todo App</h1>
        <p>This project is a comprehensive Todo application built using Laravel and Nuxt.js. It demonstrates the powerful combination of a Laravel backend with a Nuxt.js frontend, creating a full-stack application that is both efficient and scalable.</p>
        
        <h2>Core Features</h2>
        <ul>
            <li>Efficient task management with a user-friendly interface.</li>
            <li>Full CRUD (Create, Read, Update, Delete) functionality for tasks.</li>
            <li>Seamless integration between Laravel backend and Nuxt.js frontend.</li>
        </ul>

        <h2>Technical Aspects</h2>
        <p>The application leverages Laravel for backend operations, providing robust API services. Nuxt.js is used on the frontend, offering a reactive and engaging user experience. This combination ensures a high-performance and scalable application.</p>
        
        <h3>Development Stack</h3>
        <ul>
            <li>Laravel for backend API development.</li>
            <li>Nuxt.js as the frontend framework, utilizing Vue.js.</li>
            <li>Integration of modern web development practices and tools.</li>
        </ul>
        <a href='https://github.com/md-arif-islam/laravel-nuxt-js-todos' target='_blank'>View on GitHub</a>
        <img src='${require("@/assets/images/projects/laravel-projects/laravel-nuxt-js-todo.png")}' alt='Laravel & Nuxt Js Todo App'>
    
        <hr/>

        <h1>E-commerce Project with Laravel</h1>
        <p>This project is an in-progress E-commerce platform being developed using the Laravel framework. It aims to provide a comprehensive solution for online shopping with a focus on performance, scalability, and user experience.</p>
        
        <h2>Current Features and Technologies</h2>
        <ul>
            <li>Robust backend development with Laravel 9.</li>
            <li>Integration of Laravel Sanctum for API security.</li>
            <li>Shopping cart functionality using Hardevine's ShoppingCart package.</li>
            <li>Real-time interactive components with Livewire.</li>
            <li>User authentication and management with Laravel Breeze.</li>
        </ul>

        <h2>Technical Aspects</h2>
        <p>The project is built on PHP 8, leveraging Laravel's latest features for efficient web application development. Key packages include Guzzle for HTTP requests, Livewire for dynamic interfaces, and Laravel Sanctum for secure API handling.</p>
        
        <h3>Development Stack</h3>
        <ul>
            <li>Laravel Framework as the core backend technology.</li>
            <li>Livewire for dynamic front-end components.</li>
            <li>Hardevine's ShoppingCart for cart management.</li>
            <li>Laravel Breeze for simple and robust authentication.</li>
        </ul>
        <p><i>Note: This project is currently under development and more features are being actively added.</i></p>

        <a href='https://github.com/md-arif-islam/e-commerce-project-with-laravel.git' target='_blank'>View on GitHub</a>
        <img src='${require("@/assets/images/projects/laravel-projects/e_commerce_project_with_laravel.gif")}' alt='Ecommerce'>
    
        
    `,
    img: require("@/assets/images/projects/laravel-projects.webp"),
  },

  {
    id: 30,
    title:
      "Pharmacy Management System - A Complete package for web development",
    category: "Raw PHP, Laravel, React, React-Larave-Full-Stack",
    tags: ["Raw PHP", "Laravel", "React", "Full Stack"],
    live_link: "",
    github_link: "",
    color: "#4e73df",
    date: "01 September 2022", // Add the date
    desc: `
    <h1>Pharmacy Management System</h1>
    <p>A comprehensive and evolving web-based solution, this Pharmacy Management System has transitioned from a simple HTML/CSS project to a full-fledged Full Stack application using Raw PHP, Laravel, and React. Initially created in 2020, the project has been continually updated and improved over the years.</p>
    
  
    <div class='embed-container'>
      <iframe src='https://player.vimeo.com/video/890158277?h=b30da7a9ad' frameborder='0' allow='autoplay; fullscreen' allowfullscreen></iframe>
     </div>

     <h2>Project Evolution</h2>
    <ul>
        <li>2020: Raw HTML/CSS - <a href='https://github.com/md-arif-islam/admin-dashboard-fontend' target='_blank'>View on GitHub</a></li>
        <li>Conversion to Functional Application with Raw PHP - <a href='https://github.com/md-arif-islam/pharmacy-management-system-with-php-mysql' target='_blank'>View on GitHub</a></li>
        <li>Integration with Laravel - <a href='https://github.com/md-arif-islam/pharmacy-management-system-with-laravel' target='_blank'>View on GitHub</a></li>
        <li>Upgrade to React JS - <a href='https://github.com/md-arif-islam/admin-dashboard-with-react' target='_blank'>View on GitHub</a></li>
        <li>Full Stack with React JS + Laravel - <a href='https://github.com/md-arif-islam/react-laravel-full-stack' target='_blank'>View on GitHub</a></li>
    </ul>

  
   


    <h2>Documentation</h2>
    <h3>Admin:</h3>
    <ul>
        <li>Dashboard overview, manager, pharmacist, and salesman management</li>
        <li>Profile modification and password change capabilities</li>
    </ul>
    <h3>Manager, Pharmacist, Salesman:</h3>
    <ul>
        <li>Similar functionalities with varying access levels</li>
        <li>Ability to add, edit, and delete personnel based on role</li>
    </ul>

    <div class='embed-container'>
      <iframe src='https://player.vimeo.com/video/890160352?h=722f25472c' frameborder='0' allow='autoplay; fullscreen' allowfullscreen></iframe>
    </div>

    <h2>Login Information</h2>
    <p>Login as different roles to experience the system's multi-faceted interface.</p>
    <pre><code class="language-html">
    <b>Admin Login:</b>
    Email: arif@islam.com
    Password: 1234
    Role: Admin
    </code></pre>

    <h2>Technical Highlights</h2>
    <p>The system is built using a combination of Raw PHP, Laravel, and React, showcasing the integration of front-end and back-end technologies to create a robust full-stack solution.</p>

    <h6>Explore More</h6>
    <p>Discover the full potential of this Pharmacy Management System by delving into its code repositories and watching the demo videos.</p>

    `,
    img: require("@/assets/images/projects/pharmacy-management-system.webp"),
  },

  {
    id: 30.1,
    title: "MT4Gadgets - GeneratePress - Ecommerce Digital Product",
    category: "WordPress Customization",
    tags: ["WordPress", "Ecommerce", "Digital Products"],
    live_link: "https://mt4gadgets.com/",
    github_link: "",
    color: "#8c7ae6",
    date: "20 March 2023",
    desc: `
      <h2>Project Overview</h2>
      <p><strong>Project:</strong> MT4Gadgets<br>
      <strong>Industry:</strong> Digital Products, E-commerce<br>
         <strong>Platform:</strong> WordPress with GeneratePress<br>
         <strong>Website:</strong> <a href='https://mt4gadgets.com/' target='_blank'>MT4Gadgets</a></p>
      <p>MT4Gadgets is a dynamic online platform specializing in the sale of digital products. Built using WordPress and the GeneratePress theme, the website focuses on providing a variety of digital gadgets and tools. The project required a sleek, efficient, and user-friendly e-commerce environment tailored for a digital product marketplace.</p>
  
      <h2>Project Goal</h2>
      <p>The main aim was to create a reliable and intuitive online store that simplifies the process of purchasing digital gadgets. The website needed to be visually appealing, easy to navigate, and equipped with secure payment gateways to ensure a smooth shopping experience for customers.</p>
  
      <h2>Design and Development Insights</h2>
      <p>The design was centered around a minimalist and clean aesthetic, ensuring that the digital products are the focal point. The website's user interface was developed to be straightforward and accessible, making it easy for users to find and purchase products. The use of GeneratePress facilitated a responsive and fast-loading website, enhancing user experience.</p>
  
      <h2>Key Features</h2>
      <ul>
        <li><strong>Product Display:</strong> A well-organized showcase of digital gadgets with comprehensive details.</li>
        <li><strong>Secure Checkout:</strong> A streamlined and secure process for customers to complete their purchases.</li>
        <li><strong>Customer Reviews:</strong> A section for users to share their experiences and feedback on products.</li>
        <li><strong>Search Functionality:</strong> An effective search tool to help users quickly find specific products.</li>
        <li><strong>Responsive Design:</strong> A mobile-friendly interface that adjusts to different screen sizes and devices.</li>
      </ul>
  
      <h2>Outcome and Impact</h2>
      <p>MT4Gadgets has been well-received for its efficient performance and ease of use. The site effectively meets the needs of digital product shoppers, bolstering the brand's online presence and sales figures.</p>
    `,
    img: require("@/assets/images/projects/mt4gadgets-ecommerce-digital.webp"),
  },

  {
    id: 31,
    title: "Vue Todo - Practice Project",
    category: "Vue JS",
    tags: ["Vue JS", "SASS", "Local Storage"],
    live_link: "https://vue-todos-virid.vercel.app/",
    github_link: "https://github.com/md-arif-islam/vue-todos",
    color: "#22ab7a",
    date: "11 August 2023",
    desc: `
    <h1>Vue Todo - Practice Project</h1>
    <p>The Vue Todo app is a sleek and functional task management tool, designed to help users organize and track their daily tasks efficiently. Built with Vue.js, this app showcases a responsive and user-friendly interface, making task management both simple and enjoyable.</p>
    
    <h2>Core Features</h2>
    <ul>
        <li>Intuitive task addition, editing, and deletion.</li>
        <li>Tasks categorization for better organization.</li>
        <li>Persistent task storage using Local Storage.</li>
    </ul>

    <h2>Technical Aspects</h2>
    <p>This application is developed using Vue.js, emphasizing component-based architecture for a modular and maintainable codebase. SASS is used for advanced styling capabilities, enhancing the user interface with a modern and clean design.</p>
    
    <h3>Development Stack</h3>
    <ul>
        <li>Vue.js as the primary JavaScript framework</li>
        <li>SASS for advanced styling and design</li>
        <li>Local Storage for persisting tasks between sessions</li>
        <li>Iconify for integrating a wide range of icons</li>
    </ul>

    <h4>Project Insights</h4>
    <p>As a practice project, the Vue Todo app serves as a platform to explore Vue.js capabilities in depth, including state management, component structuring, and integrating external libraries.</p>

    <h5>Version Information</h5>
    <p>Currently, the app is at version <b>1.0.0</b>, indicating an ongoing development phase with potential for new features and improvements.</p>

    <h6>Explore More</h6>
    <p>View the live app <a href='https://vue-todos-virid.vercel.app/' target='_blank'>here</a> and check out the source code on GitHub at <a href='https://github.com/md-arif-islam/vue-todos' target='_blank'>this link</a>.</p>
`,
    img: require("@/assets/images/projects/vue-todo.webp"),
  },

  {
    id: 32,
    title: "Vue Weather Tracker App - Practice Project",
    category: "Vue JS",
    tags: ["Vue JS", "Tailwind CSS", "Axios"],
    live_link: "https://vue-weather-app-beta.vercel.app/",
    github_link: "https://github.com/md-arif-islam/vue-weather-app.git",
    color: "#22ab7a",
    date: "01 September 2023",
    desc: `
        <h1>Vue Weather Tracker App</h1>
        <p>The Vue Weather Tracker App is a dynamic and interactive application designed to provide real-time weather updates. Utilizing the Vue.js framework, this app stands out for its responsive design and user-friendly interface, making weather tracking both informative and enjoyable.</p>
        
        <h2>App Features</h2>
        <ul>
            <li>Real-time weather data display, including temperature, humidity, wind speed, and more.</li>
            <li>User-friendly interface with dynamic weather visuals.</li>
            <li>Seamless integration with weather APIs using Axios for up-to-date information.</li>
        </ul>

        <h2>Technical Stack</h2>
        <p>This app is built on Vue.js, leveraging its reactivity and component-based architecture for an efficient and scalable application. Tailwind CSS is used for styling, providing a sleek and modern UI, while Axios handles API requests smoothly.</p>
        
        <h3>Dependencies and Development Tools</h3>
        <ul>
            <li>Vue.js for dynamic and reactive UI</li>
            <li>Axios for handling API requests</li>
            <li>Vue Router for application routing</li>
            <li>Tailwind CSS for utility-first styling</li>
            <li>Vite as the build tool and development server</li>
        </ul>

        <h4>Project Overview</h4>
        <p>As a practice project, this app focuses on applying Vue.js and integrating various tools and libraries effectively, demonstrating modern web development techniques and practices.</p>

        <h5>Version Information</h5>
        <p>The application is currently at version 1.0.0, signifying its ongoing development and iteration.</p>

        <h6>Useful Links</h6>
        <p>Check out the live app <a href='https://vue-weather-app-beta.vercel.app/' target='_blank'>here</a> and explore the GitHub repository for more details at <a href='https://github.com/md-arif-islam/vue-weather-app.git' target='_blank'>this link</a>.</p>
    `,

    img: require("@/assets/images/projects/vue-weather-app.webp"),
  },
  {
    id: 33,
    title: "Vue Weight Tracker APP - Practice Project",
    category: "Vue JS",
    tags: ["Vue JS", "Chart JS", "Tailwind"],
    live_link: "https://vue-weight-tracker-vert.vercel.app/",
    github_link: "https://github.com/md-arif-islam/vue-weight-tracker",
    color: "#22ab7a",
    date: "01 September 2022", // Add the date
    desc: `

    <h1>Vue Weight Tracker App</h1>
    <p>The Vue Weight Tracker App, created as a practice project, is an intuitive and user-friendly application designed to help users monitor and track their weight loss journey. Developed using Vue.js, this app provides a seamless and interactive user experience.</p>
    
    <h2>Key Features</h2>
    <ul>
        <li>Easy tracking of weight changes over time.</li>
        <li>Visual representation of progress through interactive charts, powered by Chart.js.</li>
        <li>User authentication system, leveraging Laravel UI and Auth for secure access.</li>
    </ul>

    <h2>Technical Stack</h2>
    <p>This application is built with Vue.js, known for its progressive framework that is adaptable and versatile for various types of web applications. Laravel is used for backend operations, providing a robust and secure platform for user data handling.</p>
    
    <h3>Dependencies and Development Tools</h3>
    <ul>
        <li>Vue.js for frontend development</li>
        <li>Chart.js for data visualization</li>
        <li>Laravel for backend functionality</li>
        <li>Tailwind CSS for styling</li>
        <li>Vite for build and development tooling</li>
    </ul>

    <h4>Project Insights</h4>
    <p>As a practice project, this app was an opportunity to explore the integration of Vue.js with Laravel, emphasizing the application of modern web development practices and tools.</p>

    <h5>Version Information</h5>
    <p>The current version of the app is <b>1.0.0</b>, indicating its early stages of development and potential for further enhancements.</p>

    <h6>Links</h6>
    <p>Experience the Vue Weight Tracker App live <a target='_blank' href='https://vue-weight-tracker-vert.vercel.app/'>here</a>. For a deeper dive into its codebase, visit the GitHub repository at <a target='_blank' href='https://github.com/md-arif-islam/vue-weight-tracker'>this link</a>.</p>
    `,
    img: require("@/assets/images/projects/vue-weight-tracker.webp"),
  },

  {
    id: 34,
    title: "Money Manager Vue App",
    category: "Vue JS",
    tags: ["Vue JS", "Tailwind", "Firebase", "Pinia"],
    live_link: "https://money-manager-vue-app.arifislam.me",
    github_link: "https://github.com/md-arif-islam/money-manager-vue-app",
    color: "#3547c9",
    date: "20 October 2023",
    desc: `
        <h1>Money Manager Vue App</h1>
        <p>The Money Manager Vue App is an advanced and user-friendly financial management tool, designed to provide a streamlined and intuitive way to manage personal finances. Leveraging the power of Vue.js, this app offers a dynamic and responsive user experience.</p>
        
        <h2>Features</h2>
        <ul>
            <li>Effortlessly add income and expenses with details like date, amount, category, account, and notes.</li>
            <li>Customize your financial categories and accounts with options to add, remove, activate, or deactivate.</li>
            <li>Secure user authentication via email or Google account.</li>
            <li>Informative dashboard providing a real-time overview of your financial health.</li>
        </ul>

        <img src='${require("@/assets/images/projects/money-manager-vue-app/dashboard.png")}' alt='Money Manager Vue App Dashboard'>
       

        <h2>How to Use</h2>
        <p>Getting started with Money Manager Vue App is easy:</p>
        <ol>
            <li>Sign up using your email or Google account.</li>
            <li>Begin by setting up your categories and accounts to tailor the app to your needs.</li>
            <li>Track your income and expenses by adding entries with relevant details.</li>
            <li>Use the dashboard to view your financial summary at a glance.</li>
        </ol>

        <h2>Technical Stack</h2>
        <p>Built with Vue.js, the app integrates Firebase for backend operations, Pinia for state management, and Tailwind CSS for styling. It also features FontAwesome icons and Vuelidate for form validation.</p>
        
        <h3>Key Dependencies</h3>
        <ul>
            <li>Vue.js for the frontend framework</li>
            <li>Firebase for backend services</li>
            <li>Pinia for state management</li>
            <li>Tailwind CSS for styling</li>
            <li>Vuelidate for form validation</li>
            <li>FontAwesome for icons</li>
        </ul>

        <h4>Development Tools</h4>
        <p>The app's development utilized tools like Vite, Autoprefixer, and PostCSS, ensuring a smooth and efficient build process.</p>
        
        <h5>Version Details</h5>
        <p><b>Version 1.0.0</b> reflects the ongoing development and updates of the application.</p>
        
        <h6>Links</h6>
        <p>Explore the app <a target='_blank' href='https://money-manager-vue-app.arifislam.me'>here</a> and visit the GitHub repository at <a target='_blank' href='https://github.com/md-arif-islam/money-manager-vue-app'>this link</a>.</p>
        
       
    `,
    img: require("@/assets/images/projects/money-manager-vue-app.webp"),
  },

  {
    id: 35,
    title: "Product Research AI - SaaS AI Website",
    category: "WordPress Development",
    tags: [
      "WordPress",
      "Beaver Builder",
      "AI Integration",
      "SaaS",
      "Subscription",
    ],
    live_link: "https://productresearch.ai/",
    github_link: "",
    color: "#4a69bd",
    date: "28 December 2023",
    desc: `
      <h2>Project Overview</h2>
      <p><strong>Project:</strong> Product Research AI<br>
      <strong>Industry:</strong> AI-Driven SaaS, E-commerce<br>
         <strong>Platform:</strong> WordPress with Beaver Builder<br>
         <strong>Website:</strong> <a href='https://productresearch.ai/' target='_blank'>ProductResearch.ai</a></p>
      <p>Product Research AI is a cutting-edge SaaS website designed to leverage AI for e-commerce product research and related tasks. Developed using WordPress and the Beaver Builder, this project integrates OpenAI to provide innovative solutions for product research, KPI calculation, and more.</p>

      <h2>Project Goal</h2>
      <p>The goal was to create an AI-powered platform that simplifies product research and related tasks for e-commerce businesses. The website aimed to offer intuitive and efficient tools, enhancing the decision-making process for users.</p>

      <h2>Design and Development Insights</h2>
      <p>Featuring a user-friendly design, the website emphasizes ease of use and accessibility. The integration of Beaver Builder custom modules with OpenAI technology is a standout feature, offering unique functionalities like AI-driven product research and KPI calculation.</p>

      <h2>Key Features</h2>
      <ul>
        <li><strong>AI-Driven Product Research:</strong> An innovative tool integrating OpenAI for comprehensive product analysis.</li>
        <li><strong>KPI Calculator:</strong> A feature for accurately calculating key performance indicators.</li>
        <li><strong>Product Copy:</strong> Coming soon, a tool for generating product descriptions using AI.</li>
        <li><strong>Customer Persona:</strong> Upcoming feature to create detailed customer profiles.</li>
        <li><strong>Facebook Ad Copy:</strong> A future addition for creating effective ad copy with AI assistance.</li>
      </ul>

      <div class='embed-container'>
      <iframe src='https://player.vimeo.com/video/898263151' frameborder='0' allow='autoplay; fullscreen' allowfullscreen></iframe>
    </div>

      <h2>Outcome and Impact</h2>
      <p>Product Research AI has been pivotal in transforming the approach to product research in the e-commerce industry. Its AI-driven tools have enabled users to make informed decisions, enhancing productivity and efficiency.</p>
    `,
    img: require("@/assets/images/projects/product-research-ai-sass.webp"),
  },
];

const projects = projects_items.map((project) => {
  const title = project.title.replace(/ /g, "-").toLowerCase();
  const permalink = title;
  return {
    ...project,
    permalink,
  };
});

export default projects;
