<script>
import { Icon } from "@iconify/vue";

export default {
  components: {
    Icon,
  },
  data: () => {
    return {
      appTheme: localStorage.getItem("theme"),
    };
  },
  props: ["blog"],
  computed: {
    backgroundColor() {
      if (this.appTheme == "light") {
        const hex = this.blog.color.replace("#", "");
        const r = parseInt(hex.substring(0, 2), 16);
        const g = parseInt(hex.substring(2, 4), 16);
        const b = parseInt(hex.substring(4, 6), 16);
        // Convert to RGBA with 0.1 opacity
        return `rgba(${r}, ${g}, ${b}, 0.05)`;
      } else {
        return "#1E3851";
      }
    },

    shortDesc() {
      return this.blog.short_desc.split(" ").slice(0, 10).join(" ") + "...";
    },
  },
};
</script>

<template>
  <router-link
    :to="`/blog/${blog.permalink}`"
    :style="`background-color: ${backgroundColor}; border-color: ${blog.color}; `"
    class="grid grid-cols-5 gap-4 rounded-lg shadow-lg hover:shadow-xl hover:scale-[1.01] duration-300 cursor-pointer mb-5 md:mb-10 border-r-4 sm:mb-0 dark:bg-ternary-dark"
    aria-label="Single Blog"
  >
    <div
      class="relative w-full h-full col-span-2 bg-cover bg-center rounded-l-lg"
      :style="`background-image: url(${blog.img});`"
    >
      <p
        class="absolute left-2 top-2 z-20 rounded-md px-2 py-1 backdrop-blur-[10px] text-white font-bold text-sm duration-300"
      >
        {{ blog.date }}
      </p>
      <!-- <img
        :src="blog.img"
        :alt="blog.title"
        class="w-full h-full rounded-l-lg border-none brightness-90 hover:brightness-100 duration-300"
      /> -->
    </div>
    <div class="relative text-left p-2 md:p-4 col-span-3">
      <h1
        class="text-md md:text-xl text-ternary-dark dark:text-ternary-light font-semibold mb-5"
      >
        {{ blog.title }}
      </h1>

      <p
        v-if="blog.short_desc"
        class="hidden md:block text-ternary-dark dark:text-ternary-light"
      >
        {{ shortDesc }}
      </p>

      <div class="relative text-left mt-5">
        <router-link
          :to="`/blog/${blog.permalink}`"
          class="flex flex-shrink-0 flex-wrap justify-start items-center text-left text-sm text-ternary-dark dark:text-secondary-light m-1 group"
          aria-label="Download Resume"
        >
          <span
            class="text-md font-semibold duration-300 group-hover:text-accent"
            >Read More</span
          >
          <Icon
            icon="icon-park-solid:right-one"
            class="text-lg group-hover:text-accent duration-300"
          />
        </router-link>
      </div>
    </div>
  </router-link>
</template>

<style lang="scss" scoped></style>
