<template>
  <div class="text-center pt-14 md:pt-24 pb-10">
    <h1
      class="text-2xl sm:text-2xl lg:text-3xl xl:text-4xl font-bold mb-2 text-ternary-dark dark:text-ternary-light"
    >
      {{ "<" }}
      <span
        v-for="(letter, index) in titleArray"
        :key="`title-${index}`"
        class="animate-[bounceIn_1s_.5s] hover:animate-[rubberBand_1s] inline-block hover:text-accent"
        v-html="letter"
      >
      </span>
      {{ "/>" }}
    </h1>
    <p
      class="font-general-regular text-center text-secondary-dark dark:text-ternary-light text-md sm:text-xl font-normal mb-4"
    >
      {{ desc }}
    </p>
  </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  title: String,
  desc: String,
});

const titleArray = computed(() => {
  // Split the title at spaces, and then map each word to an array of characters,
  // inserting '&nbsp;' between words.
  return props.title
    .split(" ")
    .flatMap((word, index, array) =>
      word.split("").concat(index < array.length - 1 ? "&nbsp;" : [])
    );
});
</script>

<style scoped>
/* Add your CSS styles here */
</style>
